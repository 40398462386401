import React, { useState } from 'react';
import { ThumbnailSkeleton } from '../ThumbnailSkeleton/ThumbnailSkeleton';
import './ImageLoader.scss';

export const ImageLoader = ({ url, alt, width, height }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <div className="image-loader-wrapper">
            {!loaded && <ThumbnailSkeleton width={width} height={height} />}
            <img
                src={url}
                alt={alt}
                className={`thumbnail ${loaded ? 'fade-in' : 'hidden'}`}
                onLoad={() => setLoaded(true)}
                style={{ display: loaded ? 'block' : 'none' }}
            />
            <div className="enlarge-overlay">Click to Enlarge</div>
        </div>
    );
};
