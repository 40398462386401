import React, { ChangeEvent, useState } from 'react';
import { getAllAlbumNames, updateAlbumName } from '../../api/firebaseApi';
import { v4 as uuidv4 } from 'uuid';
import { InputAdornment, Box, Card, CardContent, TextField } from '@mui/material';
import { FaPencil } from 'react-icons/fa6';
import '../../styles/forms.scss';
import { Oval } from 'react-loading-icons';
import '../../styles/forms.scss';

type Props = { 
    alert: Function;
    season: string; 
    oldAlbumName: string;
    refreshHandler: Function;
    closeHandler: Function;
};

type Message = {
    id: string;
    type: string;
    message: string;
};

type ErrorStack = {
    message: string;
    stack: string;
};

type AlbumMetadata = {
    albumName: string;
    imageCount: number;
};

type AlbumData = {
    season: string;
    albums: Array<AlbumMetadata>;
};

export const UpdateAlbumNameForm = (props: Props) => {
    const { alert, season, oldAlbumName, refreshHandler, closeHandler } = props;
    const [newAlbumName, setNewAlbumName] = useState<string>(oldAlbumName);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const regex = /^[a-zA-Z0-9 _.]+$/;

    const updateNameHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setError(false);
        setIsUpdating(true);

        const existingAlbums = await getAllAlbumNames();
        if (existingAlbums[0]) {
            let nameFound = false;
            if (existingAlbums[1] && Array.isArray(existingAlbums[1])) {
                existingAlbums[1].forEach((item: AlbumData) => {
                    if (item.season === season) {
                        item.albums.forEach((album) => {
                            if (album.albumName.toLowerCase() === newAlbumName.toLowerCase()) {
                                nameFound = true;
                            }
                        });
                    }
                });
            }
            if (nameFound) {
                const message: Message = {
                    id: uuidv4(),
                    type: 'error',
                    message: `Album name: '${newAlbumName}' already exists.`,
                }
                alert(message);
                setError(true);
                setIsUpdating(false);
                return;
            }            
        }

        if (!regex.test(newAlbumName)) {
            const message: Message = {
                id: uuidv4(),
                type: 'error',
                message: 'Album name requirements not met.',
            }
            alert(message);
            setError(true);
            setIsUpdating(false);
            return;
        }

        if (newAlbumName === '' || !newAlbumName) {
            const message: Message = {
                id: uuidv4(),
                type: 'error',
                message: 'Album name cannot be blank.',
            }
            alert(message);
            setError(true);
            return;
        }
        const res = await updateAlbumName(season, oldAlbumName, newAlbumName);
        let payload: Message;
        if (res[0]) {
            payload = {
                id: uuidv4(),
                type: 'success',
                message: 'Album name updated successfully',
            }
            refreshHandler();
            alert(payload);
            closeHandler(false);
        } else if (!res[0] && res[1]) {
            console.error(res[1]);
            let message: string;
            if (typeof res[1] === 'object') {
                const error:ErrorStack = res[1];
                message = error.message;
            } else {
                message = res[1].toString();
            }
            payload = {
                id: uuidv4(),
                type: 'error',
                message,
            }
            alert(payload);
            setError(true);
        }
        setIsUpdating(false);
    };

    // Form controller submission code
    const updateFormControl = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
    ) => {
        const { value } = event.target;
        setNewAlbumName(value);
    };

    const closeModalHandler = () => {
        closeHandler(false);
    };

    // Code Adornment
    const codeAdornment = isSelected
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <FaPencil style={{fontSize: "110%"}}/>
            </InputAdornment>
        )
        }
    : {};

    return (
        <>
            <div id="update-album-name-form">
                <Box sx={{ display: 'inline-block', width: '100%'}}>
                    <Card>
                        <form>
                            <CardContent>
                                <div className="padded">
                                    <TextField 
                                        required
                                        id="newAlbumName" 
                                        label="Album Name"
                                        variant="outlined"
                                        error={error}
                                        onChange={updateFormControl}
                                        value={newAlbumName} 
                                        InputProps={codeAdornment}
                                        onFocus={() => setIsSelected(true)}
                                        onBlur={newAlbumName ? undefined : () => setIsSelected(false)}
                                        onInput={() => setIsSelected(true)}
                                        disabled={isUpdating}
                                    />
                                </div>
                            </CardContent>
                        </form>
                    </Card>
                </Box>
            </div>
            <br />
            <button 
                className="main-button modal" 
                disabled={isUpdating}
                onClick={(e) => updateNameHandler(e)}
            >
                {isUpdating ? 'Updating...' : 'Update'}
            </button>
            <button className="outline-button modal" onClick={closeModalHandler}>Cancel</button>
            {isUpdating && (
                <div className="time-div">This process may take some time <Oval /></div>
            )}
        </>
    );
};
