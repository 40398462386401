import React from 'react';
import { Skeleton } from '@mui/material';

export const ThumbnailSkeleton = (props) => {
    const { margin, width, height } = props;
    return (
        <Skeleton 
            sx={{ 
                margin, 
                borderRadius: '10px', 
                bgcolor: '#858585',
            }} 
            animation="wave" 
            variant="rectangular"
            width={width} 
            height={height} 
        />
    )
};
