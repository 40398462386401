import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { initialAccount } from './components/Utils/JsonConstants';
import Dashboard from './pages/Dashboard/Dashboard';
import { IconContext } from 'react-icons/lib';
import { Sidebar, SidebarMobile } from './components/Sidebar/Sidebar';
import { About } from './pages/About/About';
import { Schedule } from './pages/Schedule/Schedule';
import { AlertStack } from './components/Alerts/AlertStack';
import { Home } from './pages/Home/Home';
import { Auditions } from './pages/Auditions/Auditions';
import { Contact } from './pages/Contact/Contact';
import { ComingSoon } from './pages/ComingSoon/ComingSoon';
import { NotFound } from './pages/NotFound/NotFound';
import { Login } from './pages/Login/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { ReportAProblem } from './pages/ReportAProblem/ReportAProblem';
import { CreateAccount } from './pages/CreateAccount/CreateAccount';
import { AccessDenied } from './pages/AccessDenied/AccessDenied';
import Admin from './pages/Admin/Admin';
import Donate from './pages/Donate/Donate';
import { PhotoAlbums } from './pages/Media/PhotoAlbums';
import './App.scss';
import { Photos } from './pages/Media/Photos';

// Define alternate screen sizes
const screens = {
  small: window.matchMedia("all and (max-device-width: 640px)").matches,
  tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
};

// Main app
function App() {
  // Intialize state variables
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [accountData, setAccountData] = useState(initialAccount);
  const [user, setUser] = useState(undefined);

  // Function to handle opening alert messages
  const openAlertHandler = useCallback((newAlert) => {
    const alertList = [...alerts];
    if (newAlert instanceof Array) {
      for (let i = 0; i < newAlert.length; i++) {
        alertList.push(newAlert[i]);
      }
    } else {
      alertList.push(newAlert);
    }
    setAlerts(alertList);
  }, [alerts]);

  // Function to handle closing alert messages
  const closeAlertHandler = useCallback((id) => {
    const alertList = [...alerts];
    for (let i = 0; i < alertList.length; i++) {
        if (alertList[i].id === id) {
          alertList.splice(i, 1); 
        }
    }
    setAlerts(alertList);
  }, [alerts]);

  // Function to handle logged user change
  const userHandler = (newUser) => {
    setUser(newUser);
  };

  // Function to handle account data change
  const setAccountHandler = (data) => {
    setAccountData(data);
  };

  return (
    <Router>
      <main>
        <IconContext.Provider value={{className: 'translator'}}>
          {
            screens.small 
            ? <SidebarMobile /> 
            : <Sidebar 
                alert={openAlertHandler}
                user={user}
                isOpen={sideBarOpen} 
                account={accountData}
                setUser={userHandler}
                changeOpen={() => setSideBarOpen(!sideBarOpen)} 
              />
          }
          <Routes>
            <Route>
              {/* Home */}
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home sideBarOpen={sideBarOpen} />} />

              <Route element={<PrivateRoute user={user} setUser={userHandler} sideBarOpen={sideBarOpen} />}>
                {/* Admin */}
                <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />  
                <Route 
                  path="/admin/dashboard" 
                  element={
                    <Admin 
                      user={user}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={openAlertHandler}
                      close={closeAlertHandler}
                    />
                  } 
                />
                <Route 
                  path="/admin/dashboard/:nav" 
                  element={
                    <Admin 
                      user={user}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={openAlertHandler}
                      close={closeAlertHandler}
                    />
                  } 
                />
                <Route 
                  path="/admin/dashboard/:nav/:action" 
                  element={
                    <Admin 
                      user={user}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={openAlertHandler}
                      close={closeAlertHandler}
                    />
                  } 
                />

                {/* Account */}
                <Route 
                  path="/dashboard/" 
                  element={
                    <Dashboard 
                      user={user}
                      setUser={userHandler}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={openAlertHandler}
                      close={closeAlertHandler}
                    />
                  }
                />
                <Route 
                  path="/dashboard/:nav" 
                  element={
                    <Dashboard 
                      user={user}
                      setUser={userHandler}
                      account={accountData}
                      setAccount={setAccountHandler}
                      sideBarOpen={sideBarOpen} 
                      alert={openAlertHandler}
                      close={closeAlertHandler}
                    />
                  }
                />
              </Route>
              <Route path="/login" element={<Login sideBarOpen={sideBarOpen} alert={openAlertHandler} />} />
              <Route path="/create-account" element={<CreateAccount sideBarOpen={sideBarOpen} alert={openAlertHandler} />} />

              {/* About */}
              <Route path="/about" element={<About sideBarOpen={sideBarOpen} />} />

              {/* Donate */}
              <Route path="/donate" element={<Donate sideBarOpen={sideBarOpen} />} />

              {/* Media */}
              <Route path="/media/" element={<Navigate to="/media/photos" />} />
              <Route path="/media/photos" element={<PhotoAlbums sideBarOpen={sideBarOpen} alert={openAlertHandler} />} />
              <Route path="/media/photos/:season" element={<Photos sideBarOpen={sideBarOpen} alert={openAlertHandler} />} />
              <Route path="/media/videos" element={<ComingSoon sideBarOpen={sideBarOpen} />} />

              {/* Schedule */}
              <Route path="/schedule" element={<Schedule sideBarOpen={sideBarOpen} />} />

              {/* Auditions */}
              <Route path="/auditions" element={<Auditions sideBarOpen={sideBarOpen} alert={openAlertHandler} />} />

              {/* Contact */}
              <Route path="/contact" element={<Contact sideBarOpen={sideBarOpen} alert={openAlertHandler} />} />

              {/* Report */}
              <Route path="/report-a-problem-form" element={<ReportAProblem sideBarOpen={sideBarOpen} alert={openAlertHandler} />} />

              {/* Other */}
              <Route path="/access-denied" element={<AccessDenied sideBarOpen={sideBarOpen} />} />
              <Route path="/loading-screen" element={<h1>loading screen</h1>} />
              <Route path="*" element={<NotFound sideBarOpen={sideBarOpen} />} />
            </Route>
          </Routes>
          <AlertStack alerts={alerts} close={closeAlertHandler} />
        </IconContext.Provider>
      </main>
    </Router>
  );
};

export default App;
