import { useState } from 'react';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { Storage } from '../components/FirebaseConfig';
const storage = Storage();

const useUploadFiles = () => {
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [uploadResults, setUploadResults] = useState([]);

    /* 
     * parent = top parent folder
     * files = file payload (array)
     * season = season for images or piece name
     * album = album for images or instrument name
    */
    const uploadFiles = async (parent, files, season, album) => {
        if (!files || files.length === 0) {
            return [false, 'You must select at least one image for upload'];
        }
    
        setUploading(true);
        setError(null);
        setProgress(0);
    
        const imageArray = [...files];
    
        let totalBytes = 0;
    
        // Calculate total bytes for all images
        imageArray.forEach((image) => {
            totalBytes += image.size;
        });
    
        // Track progress for each file
        const uploadedBytesPerFile = Array(imageArray.length).fill(0);
    
        const uploadResults = await Promise.all(
            imageArray.map((image, index) => {
                return new Promise(async (resolve) => {
                    try {
                        const storageRef = ref(storage, `${parent}/${season}/${album}/${image.name}`);
                        const uploadTask = uploadBytesResumable(storageRef, image);
    
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                // Update per-file progress
                                uploadedBytesPerFile[index] = snapshot.bytesTransferred;
    
                                // Calculate overall progress
                                const totalUploadedBytes = uploadedBytesPerFile.reduce((acc, curr) => acc + curr, 0);
                                const overallProgress = (totalUploadedBytes / totalBytes) * 100;
    
                                setProgress(overallProgress);
                            },
                            (error) => {
                                resolve({ imageName: image.name, success: false, error: error.message });
                            },
                            () => {
                                resolve({ imageName: image.name, success: true, error: null });
                            }
                        );
                    } catch (error) {
                        resolve({ imageName: image.name, success: false, error: error.message });
                    }
                });
            })
        );
    
        const allSuccess = uploadResults.every((result) => result.success);
    
        setUploadResults(uploadResults);
        setUploading(false);
    
        return [allSuccess, uploadResults];
    };
    return { uploadFiles, uploading, progress, error, uploadResults };
};

export default useUploadFiles;
