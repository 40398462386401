import React, { useCallback, useState, useEffect } from 'react';
import { getAllAlbumNames } from '../../api/firebaseApi';
import { AlbumCard } from '../../components/Cards/AlbumCard';
import { AlertBanner } from '../../components/Alerts/AlertBanner';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import { Oval } from 'react-loading-icons';
import './PhotoAlbums.scss';

// Define page background
const bodyStyle = `
    body { 
        background-image: url('/public-images/photos.jpg'); 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function PhotoAlbums(props) {
    // Get props and set state variables
    const { alert, sideBarOpen } = props;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [screenSize, setScreenSize] = useState(screens);
    const [skeletonWidth, setSkeletonWidth] = useState(375);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }

        if (prev.small) {
            setSkeletonWidth(250);
        } else if (prev.tabletPort) {
            setSkeletonWidth(270);
        } else if (prev.tabletLand) {
            setSkeletonWidth(275);
        }

        setScreenSize(prev);
    }, []);

    const alertHandler = useCallback((payload) => {
        alert(payload);
    }, [alert]);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            const res = await getAllAlbumNames();
            if (res[0]) {
                setData(res[1]);
                setIsError(false);
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: res[1],
                    noClose: true,
                }
                alertHandler(payload);
                setIsError(true);
            }
            setIsLoading(false);
        }
        if (!isError && data.length === 0) {
            fetch();
        }
    }, [alertHandler, isError, data.length]);

    return (
        <section id="photos-section" style={!screenSize.small ? { width: sideBarOpen ? '85%' : '97%' } : {}}>
            <Helmet>
                <title>Chucktown Sound | Photos</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="photos-header-wrapper">
                <div id="photos-album-title-wrapper" className="photos-title-album">
                    <h1>Photo Albums</h1>
                </div>
            </div>
            <div id="photos-wrapper-div" className="photos-wrapper-div">
                <div className="content-wrapper">
                    {data.length > 0 && data.map((item) => {
                        if (item.albums && item.albums.length > 0) {
                            const rows = screens.tabletLand ? 2 : 3;
                            const mod = item.albums.length % rows;
                            const fillersNeeded = mod === 0 ? 0 : rows - mod;
                            let fillers = [];
                            if (fillersNeeded > 0) {
                                fillers = new Array(fillersNeeded).fill(' ');
                            }
                            return (
                                <div key={uuidv4()} id="photos-content" className="photos-content">
                                    <h2>{item.season}</h2>
                                    {item.albums.map((album) => (
                                        <AlbumCard 
                                            key={uuidv4()}
                                            title={album.albumName} 
                                            season={item.season}
                                            sideBarOpen={sideBarOpen} 
                                            alert={alert} 
                                            thumbnailWidth={skeletonWidth} 
                                            thumbnailHeight={250} 
                                        />
                                    ))}
                                    {(!screenSize.small && !screenSize.tabletPort && item.albums.length > rows && fillers.length > 0) &&
                                        fillers.map(() => <div key={uuidv4()} className="filler" />
                                    )}
                                </div>
                            );
                        }
                        return <></>;
                    })}

                    {isLoading && <Oval />}
                    {!isLoading && data.length === 0 && (
                        <AlertBanner
                            id="no-albums-banner"
                            type="info"
                            message="There are currently no albums to display."
                            noHeader
                        />
                    )}
                </div>
            </div>
        </section>
    );
};
