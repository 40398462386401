import React, { useCallback, useState, useEffect } from 'react';
import { getImagesByAlbum } from '../../api/firebaseApi';
import { AlertBanner } from '../../components/Alerts/AlertBanner';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import { ThumbnailSkeleton } from '../../components/ThumbnailSkeleton/ThumbnailSkeleton';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { capitalizeWords, toIdFormat } from '../../components/Utils/stringUtils';
import { EnlargeImageModal } from '../../components/Modals/EnlargeImageModal';
import { ImageLoader } from '../../components/ImageLoader/ImageLoader';
import './PhotoAlbums.scss';

// Define page background
const bodyStyle = `
    body { 
        background-image: url('/public-images/photos.jpg'); 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function Photos(props) {
    // Get props and set state variables
    const { alert, sideBarOpen } = props;
    const [urls, setUrls] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [enlargeUrl, setEnlargeUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [screenSize, setScreenSize] = useState(screens);
    const [skeletonWidth, setSkeletonWidth] = useState(375);
    const [skeletonHeight, setSkeletonHeight] = useState(250);
    const [fillers, setFillers] = useState([]);
    const { season }  = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const album = query.get('album');
    const placeholderArray = Array(15).fill(' ');

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }

        if (prev.small || prev.tabletPort) {
            setSkeletonWidth(275);
        } else if (prev.tabletLand) {
            setSkeletonWidth(275);
        } else if (prev.large) {
            setSkeletonWidth(500);
            setSkeletonHeight(375)
        }
        setScreenSize(prev);
    }, []);

    const alertHandler = useCallback((payload) => {
        alert(payload);
    }, [alert]);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            console.log(album)
            const res = await getImagesByAlbum(season, album.replaceAll('-', ' '));
            if (res[0]) {
                const urls = res[1];
                setUrls(urls);
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: res[1],
                }
                alertHandler(payload);
            }
            setIsLoading(false);
        };
        if (season && album) {
            fetch();
        }
    }, [alertHandler, album, season]);

    useEffect(() => {
        if (urls.length > 0) {
            const rows = screens.tabletPort ? 2 : 3;
            const mod = urls.length % rows;
            const fillersNeeded = mod === 0 ? 0 : rows - mod;
            let fillers = [];
            if (fillersNeeded > 0) {
                fillers = new Array(fillersNeeded).fill(' ');
                setFillers(fillers);
            }
        }
    }, [urls]);

    const openModalHandler = (url) => {
        setEnlargeUrl(url);
        setIsModalOpen(true);
    };

    const closeModalHandler = () => {
        setIsModalOpen(false);
    };

    const handleBackButton = () => {
        navigate('/media/photos');
    };

    return (
        <section id="photos-section" style={!screenSize.small ? { width: sideBarOpen ? '85%' : '97%' } : {}}>
            <Helmet>
                <title>Chucktown Sound | {capitalizeWords(album?.replaceAll('-', ' '))}</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="photos-header-wrapper">
                <div id="photos-title-wrapper" className="photos-title">
                    {!screens.small && <button className="main-button" onClick={handleBackButton}>Back</button>}
                    {album 
                        ? <h1>{capitalizeWords(album?.replaceAll('-', ' '))}</h1>
                        : <h1>No Album Specified</h1>
                    }
                    {screens.small && <button className="main-button" onClick={handleBackButton}>Back</button>}
                </div>
            </div>
            <div id="photos-wrapper-div" className="photos-wrapper-div">
                {(screenSize.small || screenSize.tabletPort) && <h3>Click an image to enlarge</h3>}
                <div id="photos-content" className="photos-content">
                    {isLoading && placeholderArray.map(() => (
                        <ThumbnailSkeleton 
                            key={uuidv4()}
                            width={skeletonWidth} 
                            height={skeletonHeight} 
                            margin={screens.tabletLand ? '10px' : '5px'} 
                        />
                    ))}
                    {!isLoading && urls && urls.length > 0 && (
                        urls.map((url) => (
                            <div 
                                key={`${toIdFormat(url)}-wrapper`} 
                                className="image-wrapper" 
                                onClick={() => openModalHandler(url)}
                            >
                                <ImageLoader 
                                    url={url} 
                                    alt={`${toIdFormat(url)}-Thumbnail`}
                                    width={skeletonWidth} 
                                    height={skeletonHeight} 
                                />
                            </div>
                        ))
                    )}
                    {(!screenSize.small && !screenSize.tabletPort && urls.length > 3 && fillers.length > 0) &&
                        fillers.map(() => <div key={uuidv4()} className="filler" />
                    )}
                    {(!isLoading && (!urls || urls.length === 0)) && (
                        <div>
                            <AlertBanner type="error" noHeader message="No images found" />
                        </div>
                    )}
                </div>
                <button className="main-button" onClick={handleBackButton}>Back</button>
            </div>

            {isModalOpen && (
                <EnlargeImageModal closeHandler={closeModalHandler} url={enlargeUrl} />
            )}
        </section>
    );
};
