import React from 'react';
import { MdClose } from 'react-icons/md';
import { CreateNewAttendanceForm } from '../Forms/CreateNewAttendanceForm.tsx';
import { CreateNewFoodOrderForm } from '../Forms/CreateNewFoodOrderForm.tsx';
import './Modals.scss';
import './NewFormModal.scss';

export function NewFormModal(props) {
    // Get props and initalize state variables
    const { alert, closeHandler, refresh, username, type } = props;
    
    const handleClose = (action) => {
        closeHandler(action)
    };

    const alertHandler = (payload) => {
        alert(payload);
    };

    const refreshHandler = () => {
        refresh();
    };

    return (
        <>
            <div id="new-attendance-form-modal" className="create-new-form-modal">
                <div className={`create-new-form-modal-content${type ===  'food-order' ? ' food-order' : ''}`}>
                    <div id="close-new" className="modal-close" onClick={handleClose}>
                        <MdClose />
                    </div>
                    {type === 'attendance' && (
                        <CreateNewAttendanceForm 
                            alert={alertHandler} 
                            close={handleClose} 
                            refresh={refreshHandler}
                            username={username}
                        />
                    )}

                    {type === 'food-order' && ( 
                        <CreateNewFoodOrderForm 
                            alert={alertHandler} 
                            close={handleClose} 
                            refresh={refreshHandler}
                            username={username}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
