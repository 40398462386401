import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import './Modals.scss';
import './DeleteFormConfirmationModal.scss';
import { deleteAttendanceForm, deleteFoodOrderForm } from '../../api/firebaseApi';
import { v4 as uuidv4 } from 'uuid';
import Oval from 'react-loading-icons/dist/esm/components/oval';

export function DeleteFormConfirmationModal(props) {
    // Get props and initalize state variables
    const { alert, closeHandler, refresh, target, type } = props;
    const [submitting, setSubmitting] = useState(false);
    
    const handleClose = (action) => {
        closeHandler(action)
    };

    const alertHandler = (payload) => {
        alert(payload);
    };

    const refreshHandler = () => {
        refresh();
    };

    const deleteFormHandler = async () => {
        setSubmitting(true);
        let res = [false, 'Error 501: Not implemented'];
        if (type === 'attendance') {
            res = await deleteAttendanceForm(target.uuid);
        } else if (type === 'food-order') {
            res = await deleteFoodOrderForm(target.uuid);
        }
        
        if (res[0]) {
            const payload = {
                id: uuidv4(),
                type: 'success',
                message: `${target.name} was deleted successfully!`,
            };
            alertHandler(payload);
            refreshHandler();
            handleClose(true);
        } else {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: res[1],
            };
            alertHandler(payload);
        }
    };

    return (
        <>
            <div id="delete-form-modal" className="delete-form-modal">
                <div className="delete-form-modal-content">
                    <div id="close-delete" className="modal-close" onClick={handleClose}>
                        <MdClose />
                    </div>
                    <div>
                        <h1>Are You Sure?</h1>
                        <p>Are you sure you want to delete <strong>{target.name}</strong>?</p>
                        <p className="underlined">This action cannot be undone.</p>
                        <button 
                            className="main-button modal" 
                            disabled={submitting}
                            onClick={deleteFormHandler}
                        >
                            {submitting ? 'Deleting...' : 'Delete'}
                        </button>
                        <button className="outline-button modal" onClick={handleClose}>Cancel</button>
                    </div>
                    {submitting ? <div><Oval /></div> : <></>}
                </div>
            </div>
        </>
    );
};