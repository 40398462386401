import { 
    InputLabel, 
    MenuItem, 
    Select, 
    SelectChangeEvent, 
    TextField,
} from '@mui/material';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getAllAlbumNames } from '../../api/firebaseApi';
import { AlertBanner } from '../Alerts/AlertBanner';
import useUploadFiles from '../../api/useUploadFiles.jsx';
import CircularProgressWithLabel from '../CircularProgress/CircularProgressWithLabel.tsx';

type Props = { 
    alert: Function; 
    refreshHandler: Function;
    refresh: boolean;
};

type Message = {
    id: string;
    type: string;
    message: string;
    noClose: boolean;
};

type AlbumMetadata = {
    albumName: string;
    imageCount: number;
};

type PhotoAlbumData = {
    season: string;
    albums: Array<AlbumMetadata>;
}

type PhotoAlbumDataArray = Array<PhotoAlbumData>;

export function UploadImageForm(props: Props) {
    const { alert, refreshHandler, refresh } = props;
    const [album, setAlbum] = useState<string>('');
    const [season, setSeason] = useState<string>('');
    const [images, setImages] = useState<FileList | null>(null);
    const [imageError, setImageError] = useState<boolean>(false);
    const [albumError, setAlbumError] = useState<boolean>(false);
    const [albumData, setAlbumData] = useState<boolean | null | PhotoAlbumDataArray>(false);
    const [isError, setIsError] = useState(false);

    const { uploadFiles, uploading, progress, error } = useUploadFiles();

    const alertHandler = useCallback((payload: Message) => {
        alert(payload);
    }, [alert]);

    useEffect(() => {
        if (error) {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: error,
                noClose: true,
            }
            alertHandler(payload)
        }
    }, [error, alertHandler]);

    const makeApiCall = useCallback( async () => {
        const list = await getAllAlbumNames();
        if (list[0]) {
            const data = list[1];
            if (data && typeof data === 'object') {
                setAlbumData(data);
                setIsError(false);
            }
            refreshHandler();
        } else {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: list[1],
                noClose: true,
            }
            alert(payload);
            setIsError(true);
        }
    }, [alert, refreshHandler])

    useEffect(() => {
        if ((!albumData || refresh) && !isError) {
            makeApiCall();
        }
    }, [albumData, refresh, makeApiCall, isError]);

    const resetForm = () => {
        setAlbum('');
        setSeason('');
        setImages(null);
    };

    const handleUpload = async (e: FormEvent) => {
        e.preventDefault();
        setImageError(false);
        setAlbumError(false);
    
        if (!images || (images && images.length === 0)) {
            const message = {
                id: uuidv4(),
                type: 'error',
                message: 'Please select an image to upload!',
                noClose: false,
            }
            alert(message);
            setImageError(true);
            return;
        }
    
        if (album === '' || !album) {
            const message: Message = {
                id: uuidv4(),
                type: 'error',
                message: 'Please select an album to upload to',
                noClose: false,
            }
            alert(message);
            setAlbumError(true);
            return;
        }
    
        const [allSuccess, results] = await uploadFiles('images', images, season, album);
        let message: Message;
    
        if (allSuccess) {
            message = {
                id: uuidv4(),
                type: 'success',
                message: 'Image(s) uploaded successfully!',
                noClose: true,
            };
            alert(message);
            refreshHandler();
            resetForm();
        } else if (results && Array.isArray(results)) {
            const errorMessages = results.filter(result => result.error).map(result => result.error.toString());
            if (errorMessages.length > 0) {
                message = {
                    id: uuidv4(),
                    type: 'error',
                    message: errorMessages.join(', '),
                    noClose: false,
                };
                alert(message);
            }
        }
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const [selectedSeason, selectedAlbum] = e.target.value.split('|');
        setAlbum(selectedAlbum);
        setSeason(selectedSeason);
    };

    const imageChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setImages(e.target.files);
        }
    };

    return (
        <div className="wrapper-div">
            {(albumData && typeof albumData === 'object' && albumData.length === 0) && (
                <AlertBanner type="info" noHeader message="No albums found." />
            )}
            <form onSubmit={handleUpload}>
                <h3 id="upload-image-label">Upload Images</h3>
                <p id="upload-instructions">Upload one or multiple images</p>
                <TextField
                    required 
                    type="file"
                    error={imageError} 
                    value={!images ? '' : undefined}
                    onChange={imageChangeHandler}
                    inputProps={{ multiple: true }}
                />
                <div>
                    <InputLabel id="album-label" required>Select Album</InputLabel>
                    <Select 
                        id="album-select"
                        labelId="album-label"
                        label="Select Album"
                        onChange={handleSelectChange}
                        value={season && album ? `${season}|${album}` : ''} 
                        error={albumError}
                    >
                        {(albumData && typeof albumData === 'object' && albumData.length > 0) && (
                            albumData.map((data) => {
                                const season = data.season;
                                const albums = data.albums;
                                return (
                                    albums.map((album) => (
                                        <MenuItem key={`${season}-${album.albumName}`} value={`${season}|${album.albumName}`}>
                                            {album.albumName}
                                        </MenuItem>
                                    )
                                )
                            )})
                        )}
                    </Select>
                    <button className="main-button" disabled={uploading}>{uploading ? 'Uploading...' : 'Upload'}</button>
                    {uploading && <CircularProgressWithLabel value={progress}/>}
                </div>
            </form>
        </div>
    );
};
