import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSingleFoodOrderForm } from '../../api/firebaseApi';
import { v4 as uuidv4 } from 'uuid';
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    FormControlLabel,
    Radio, 
    RadioGroup,
} from '@mui/material';
import { FcInfo } from 'react-icons/fc';
import  { RiArrowDropDownLine } from 'react-icons/ri';
import { Chip } from '@mui/material';
import { DataTablePagination } from '../TablePagination/DataTablePagination';
import { Oval } from 'react-loading-icons';
import { generateFoodOrderPDF } from '../Utils/ExportPDF';
import './AdminViewFoodOrderForm.scss';

// Define alternate screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tablet: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px)').matches,
};

export function AdminViewFoodOrderForm(props) {
    const { alert, uuid } = props;
    const [formData, setFormData] = useState({
        CreatedDate: '',
        Title: '',
        ExpirationDate: '',
        Submissions: [{
            data: {
                Choice: {
                    Choice: {
                        Drink: '',
                        Entree: '',
                        Side: '',
                    }
                },
                
            }
        }],
        Options: {},
        Status: false,
        CreatedBy: '',
        StartDate: '',
    });
    // TODO: Set up table sorting
    // eslint-disable-next-line no-unused-vars
    const [formSortBy, setFormSortBy] = useState('LastName');
    const [formDataLength, setFormDataLength] = useState(0);
    const [rowsPerPageFormData, setRowsPerPageFormData] = useState(5);
    const [pageFormData, setPageFormData] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [pdfOption, setPdfOption] = useState('orders-only');
    const [screenSize, setScreenSize] = useState(screens);
    const startIndexFormData = pageFormData * rowsPerPageFormData;
    const endIndexFormData = startIndexFormData + rowsPerPageFormData;
    
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    const handleRowsChangeFormData = (event) => {
        const { value } = event.target;
        setRowsPerPageFormData(parseInt(value));
    };

    const handlePageChangeFormData = (_, newPage) => {
        setPageFormData(newPage);
    };

    const emptyRowsFormData = (pageFormData > 0 || formDataLength < 5)
        ? Math.max(0, (1 + pageFormData) * rowsPerPageFormData - formDataLength) 
        : 0
    ;

    const pdfOptionChangeHandler = (event) => {
        const { value } = event.target;
        setPdfOption(value);
    };

    const alertHandler = useCallback((payload) => {
        alert(payload);
    }, [alert]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        const form = await getSingleFoodOrderForm(uuid, formSortBy);
        if (form[0]) {
            setFormDataLength(form[1].Submissions.length);
            setFormData(form[1]);
        } else {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: form[1],
            }
            alertHandler(payload);
        }
        setIsLoading(false);
    }, [alertHandler, uuid, formSortBy]);

    useEffect(() => {
        if (formData.Title === '') {
            fetchData();
        }
    }, [alertHandler, fetchData, formData.Title]);

    const generatePDFHandler = () => {
        setIsExporting(true);
        const response = generateFoodOrderPDF(pdfOption, formData, generateOrderData(), countTotal(), countOrders());
        const payload = {
            id: uuidv4(),
            type: '',
            message: '',
        }
        if (response[0]) {
            payload.type = 'success';
            payload.message = response[1];
        } else {
            payload.type = 'error';
            payload.message = response[1];
        }
        alertHandler(payload);
        setIsExporting(false);
    };

    const countOrders = () => {
        const filteredData = formData.Submissions;
        const count = {};
        for (const entry in filteredData) {
            const choiceKey = Object.keys(filteredData[entry].data.Choice)[0];
            if (!!count[choiceKey]) {
                count[choiceKey]++;
            } else {
                count[choiceKey] = 1;
            }
        };
        return count;
    };

    const generateOrderData = () => {
        const data = formData.Submissions;
        const result = {Entrees: [], Sides: [], Drinks: []};
        if (!!data) {
            data.forEach(item => {
                const key = Object.keys(item.data.Choice);
                const choice = item.data.Choice;
            
                if (!!choice && Object.keys(choice).length !== 0) {
                    // Check and normalize to lowercase
                    const entree = choice[key].Entree;
                    const side = choice[key].Side;
                    const drink = choice[key].Drink;
            
                    // Count Entrees
                    if (!!entree && entree.toLowerCase() !== 'none') {
                        const entreeIndex = result.Entrees.findIndex(e => e[entree]);
                        if (entreeIndex !== -1) {
                            result.Entrees[entreeIndex][entree]++;
                        } else {
                            result.Entrees = updateCategory(result.Entrees, entree);
                        }
                    }
            
                    // Count Sides
                    if (!!side && side.toLowerCase() !== 'none') {
                        const sideIndex = result.Sides.findIndex(s => s[side]);
                        if (sideIndex !== -1) {
                            result.Sides[sideIndex][side]++;
                        } else {
                            result.Sides = updateCategory(result.Sides, side);
                        }
                    }
            
                    // Count Drinks
                    if (!!drink && drink.toLowerCase() !== 'none') {
                        const drinkIndex = result.Drinks.findIndex(d => d[drink]);
                        if (drinkIndex !== -1) {
                            result.Drinks[drinkIndex][drink]++;
                        } else {
                            result.Drinks = updateCategory(result.Drinks, drink);
                        }
                    }
                }
            });
        }
      
        return result;
    };

    const updateCategory = (categoryArray, item) => {
        const index = categoryArray.findIndex((e) => e[item]);
        if (index !== -1) {
          categoryArray[index][item]++;
        } else {
          categoryArray.push({ [item]: 1 });
        }
        return categoryArray;
    };

    const optionList = () => {
        let content = <></>;
        let list = [<></>]
        if (formData.Options === undefined) {
            return list;
        } else {
            const options = formData.Options;
            const orderCounts = countOrders();
            for (const option in options) {
                content = (
                    <div key={`${option}-div`}>
                        <p>{option}</p>
                        <ul>
                            <li><span>Entree:</span> {options[option].Entree}</li>
                            <li><span>Side:</span> {options[option].Side}</li>
                            <li><span>Drink:</span> {options[option].Drink}</li>
                        </ul>
                        <p className="count">Orders: {orderCounts[option] ? orderCounts[option] : 0}</p>
                    </div>
                );
                list.push(content);
            }
            return list;
        }
    };

    const countTotal = () => {
        const orderTotals = countOrders();
        let count = 0;
        for (const entry in orderTotals) {
            count += orderTotals[entry];
        }
        return count;
    };


    return (
        <div id="view-form" className="view-form">
            <h1 id="form-title">Food Order Form: {formData.Title}</h1>
            <div id="view-form-content">
                <Accordion style={{width: 'fit-content'}}>
                    <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
                        Form Details
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="details">
                            <p><strong>Title:</strong> {formData.Title}</p>
                            <p><strong>Form UUID:</strong> {uuid}</p>
                            <p><strong>Created By:</strong> {formData.CreatedBy} <strong>on</strong> {formData.CreationDate}</p>
                            <p><strong>Start Date:</strong> {formData.StartDate}</p>
                            <p><strong>Expiration Date:</strong> {formData.ExpirationDate}</p>
                            <p><strong>Current Status:</strong> {formData.Status ? 'Open' : 'Closed'}</p>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="order-details">
                <div>
                    <h4>Order Totals</h4>
                    {!!formData.Submissions && (
                        Object.entries(generateOrderData()).map(([category, items]) => (
                            <div key={category}>
                                <p>{category}:</p>
                                {items.map((item, index) => {
                                    const itemName = Object.keys(item)[0];
                                    const itemQuantity = item[itemName];
                                    return (
                                        <p id={`items-${itemName}-${index}`} className="items" key={index}>
                                            • {itemName}: {itemQuantity}
                                        </p>
                                    );
                                })}
                            </div>
                        ))
                    )}
                    <h4 id="total-orders">Total Orders: {countTotal()}</h4>
                </div>
                {optionList().map((option) => option)}
            </div>
            <h3>{formData.Title} Submissions</h3>
            <div className="table-div">  
                <table id="submissions-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Submission UUID</th>
                            <th>Timestamp</th>
                            <th>Order</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formDataLength > 0 && ((rowsPerPageFormData > 0
                                ? formData.Submissions.slice(startIndexFormData, endIndexFormData)
                                : formData.Submissions
                            ).map((row, key, index) => {
                                const choiceKeys = Object.keys(row.data.Choice);
                                const hasComments = row.data.Comments.toLowerCase() !== 'none' && !!row.data.Comments;
                                return (
                                    <tr key={`${key}-${row.key}`}>
                                        <td>{index.indexOf(row) + 1}</td>
                                        <td>{row.data.LastName}, {row.data.FirstName}</td>
                                        <td>{row.key}</td>
                                        <td>{row.data.Timestamp}</td>
                                        <td className="yellow">{choiceKeys[0]}</td>
                                        <td className={hasComments ? 'yellow' : ''}>
                                            {hasComments ? row.data.Comments : '---'}
                                        </td>
                                    </tr>
                                )
                            }
                        ))}
                        {(formDataLength !== 0 && emptyRowsFormData > 0) && (
                            <tr style={{ height: 25.5 * emptyRowsFormData }}>
                                <td colSpan={6} aria-hidden />
                            </tr>
                        )}
                        {formDataLength === 0 && (
                            <tr>                                
                                {isLoading 
                                    ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                    : <td colSpan={6}>
                                        <Chip 
                                            className="MuiChip-root MuiChip-root info-table"
                                            icon={<FcInfo />} 
                                            label="No Submissions to Display" 
                                            variant="outlined" 
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
                {formDataLength > 0 && (
                    <DataTablePagination
                        length={formDataLength}
                        rowsPerPage={rowsPerPageFormData}
                        page={pageFormData}
                        handlePageChange={handlePageChangeFormData}
                        handleRowsPerPageChange={handleRowsChangeFormData}
                    />
                )}

                <div className="page-actions">
                    <div>
                        <Link to="/admin/dashboard/attendance">
                            <button className="main-button" style={{marginLeft: 0}}>Back</button>
                        </Link>
                    </div>
                    <RadioGroup
                        row
                        name="radio-buttons-group" 
                        defaultValue="full-details"
                        value={pdfOption}
                        onChange={(e) => pdfOptionChangeHandler(e)}
                    >
                        <FormControlLabel 
                            value="full-details" 
                            control={<Radio />} 
                            label="Full Order Details" 
                        />
                        <FormControlLabel 
                            value="orders-only" 
                            control={<Radio />} 
                            label="Order Data Only" 
                        />
                        {!screenSize.small && (isExporting
                            ? <Oval className="refresh-oval" /> 
                            : <button 
                                id="export-button"
                                className="main-button"
                                onClick={generatePDFHandler} 
                                disabled={formDataLength === 0}
                            >
                                Export PDF
                            </button>
                        )}
                    </RadioGroup>
                    {screenSize.small && (isExporting
                        ? <Oval className="refresh-oval" /> 
                        : <button 
                            id="export-button"
                            className="main-button"
                            onClick={generatePDFHandler} 
                            disabled={formDataLength === 0}
                        >
                            Export PDF
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
