import React from 'react';
import pfp from '../../assets/image-placeholder.jpeg';
import { AiFillHome } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { FaGear } from 'react-icons/fa6';
import { sendLogOut } from '../../api/firebaseApi';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import '../../pages/Dashboard/Dashboard.scss';

export function DashboardHeader(props) {
    const {
        screens, 
        user, 
        setUser,
        title, 
        alert, 
        account, 
        sideBarOpen,
    } = props;
    const name = `${account.FirstName} ${account.LastName}`;

    // Function to handle log out
    const logOutHandler = async () => {
        const loggedOut = await sendLogOut();
        if (loggedOut[0]) {
            const alertPayload = {
                id: uuidv4(),
                type: 'success',
                message: 'You have been successfully logged out.',
            };
            setUser(null);
            alert(alertPayload);
        } else {
            const alertPayload = {
                id: uuidv4(),
                type: 'error',
                message: `${loggedOut[1]}`,
            };
            alert(alertPayload);
        }
    };

    // Function to handle opening settings (non functional - outputs an error message)
    const settingsHandler = () => {
        const alertPayload = {
            id: uuidv4(),
            type: 'error',
            message: 'Setting button functions are not yet available',
        };
        alert(alertPayload);
    };

    return (
        <>
            <div id="dashboard-header-wrapper">
                {(!screens.tablet && !screens.small) && (
                    <div id="profile-wrapper" className="profile-wrapper">
                        <img src={pfp} alt="pfp.png" width="25px" />
                        {!!user
                            ? <div>
                                <h1 style={screens.tabletPort ? {fontSize: 16} : {}}>{name}</h1>
                                <p>
                                    {account.Student ? 'Student' : ''} 
                                    {account?.Staff ? 'Staff' : ''}
                                    &nbsp;•&nbsp;
                                    {account?.Instrument[0]}
                                </p>
                            </div>
                            : <h1>
                                <Link to="/login">Log In</Link>
                                <span> / </span>
                                <Link to="/create-account">Create</Link>
                            </h1>
                        }
                    </div>
                )}
                <div 
                    id="dashboard-title-wrapper" 
                    className="dashboard-title" 
                    style={
                        !screens.small 
                        ? { width: sideBarOpen ? '41%' : '49%' } 
                        : {}
                    }
                >
                    <h1>{title}</h1>
                </div>
                {!screens.small && (
                    <div id="dashboard-actions" className="dashboard-actions">
                        {true
                            ? (
                                <>
                                    <Link to="/dashboard">
                                        <button className="main-button">
                                            <AiFillHome /> {screens.tabletPort ? '' : 'Dashboard Home'}
                                        </button>
                                    </Link>
                                    <button className="main-button" onClick={logOutHandler}>
                                        <BiLogOut />{screens.tabletPort ? '' : ' Log Out'}
                                    </button>
                                    <button className="main-button" onClick={settingsHandler}>
                                        <FaGear />{screens.tabletPort ? '' : ' Settings'}
                                    </button>
                                </>
                            )
                            : <div style={{width: '21.75em'}} />
                        }
                    </div>
                )}
            </div>
            {(screens.small && !!user) && (
                <div id="dashboard-actions" className="dashboard-actions">
                    <Link to="/dashboard">
                        <button className="main-button">
                            <AiFillHome /> Dashboard
                        </button>
                    </Link>
                    <button className="main-button" onClick={logOutHandler}>
                        <BiLogOut /> Log Out
                    </button>
                    <button className="main-button" onClick={settingsHandler}>
                        <FaGear /> Settings
                    </button>
                </div>
            )}
        </>
    );
};
