import React, { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AiOutlineReload } from 'react-icons/ai';
import { FcInfo } from 'react-icons/fc';
import { CiCirclePlus } from 'react-icons/ci';
import { getAllFoodOrderForms } from '../../api/firebaseApi';
import { Chip, MenuItem, Select } from '@mui/material';
import { DataTablePagination } from '../TablePagination/DataTablePagination';
import { Oval } from 'react-loading-icons';
import { blankTimeForm } from '../Utils/JsonConstants';
import { NewFormModal } from '../Modals/NewFormModal';
import { FormActionModal } from '../Modals/FormActionModal';
import Countdown from '../Utils/Countdown';
import { DeleteFormConfirmationModal } from '../Modals/DeleteFormConfirmationModal';
import './AdminDashboards.scss';

// Attendance Dashboard Component
export function FoodOrdersDashboard(props) {
    // Set Props constants, and states
    const initialDeleteTarget = { name: '', uuid: '' }
    const { alert, account } = props;
    const [foodOrderForms, setFoodOrderForms] = useState([]);
    const [formTimeData, setFormTimeData] = useState(blankTimeForm);
    const [deleteTarget, setDeleteTarget] = useState(initialDeleteTarget);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshLoading, setIsRefreshLoading] = useState(false);
    const [length, setLength] = useState(0);
    const [actionVal, setActionVal] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEndModalOpen, setIsEndModalOpen] = useState(false);
    const [isStartModalOpen, setIsStartModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Determines number of empty data rows
    const emptyRows = page >= 0 
        ? Math.max(0, (1 + page) * rowsPerPage - length) 
        : 0
    ;

    // Table pagination page change handler
    const handlePageChange = (event, newPage) => {
        event.preventDefault();
        setPage(newPage);
    };

    // Table pagination rows per page change handler
    const handleRowsPerPageChange = (event) => {
        const { value } = event.target;
        setRowsPerPage(parseInt(value, 10));
        setPage(0);
    };

    // Alert handler
    const alertHandler = (payload) => {
        alert(payload);
    };

    // Function to maunally refresh data table 
    const refreshHandler = async () => {
        setIsRefreshLoading(true);
        const foodOrderFormData = await getAllFoodOrderForms();
        if (foodOrderFormData[0]) {
            setLength(foodOrderFormData[1].length);
            setFoodOrderForms(foodOrderFormData[1]);
            const actionsList = Array(foodOrderFormData[1].length).fill('').map(() => 'action');
            setActionVal(actionsList);
        } else {
            const errorPayload = {
                id: uuidv4(),
                type: 'error',
                message: foodOrderFormData[1],
            }
            alert(errorPayload);
        }
        setIsRefreshLoading(false);
    };

    // Function the UseEffect uses to get table data
    const getData = useCallback(async () => {
        if (length === 0) {
            setIsLoading(true);
            const foodOrderFormData = await getAllFoodOrderForms();
            if (foodOrderFormData[0]) {
                setLength(foodOrderFormData[1].length);
                setFoodOrderForms(foodOrderFormData[1]);
                const actionsList = Array(foodOrderFormData[1].length).fill('').map(() => 'action');
                setActionVal(actionsList);
            } else {
                const errorPayload = {
                    id: uuidv4(),
                    type: 'error',
                    message: foodOrderFormData[1],
                }
                alert(errorPayload);
            }
            setIsLoading(false);
        }
    }, [alert, length]);

    // UseEffect to populate data
    useEffect(() => {
        getData();
    }, [getData]);

    // Function to handle action options select 
    const actionSelectChangeHandler = (event, row, index) => {
        const { value } = event.target;
        if (value === 'view') {
            window.location.href = `/admin/dashboard/food-orders/view?uuid=${row.key}`;
        } else if (value === 'delete') {
            setDeleteTarget({
                name: row.data.Title,
                uuid: row.key,
            });
            setIsDeleteModalOpen(true);
        } else {
            const formData = {
                StartDate: row.data.StartDate,
                ExpirtationDate: row.data.ExpirationDate,
                FormUUID: row.key,
            };
            setFormTimeData(formData);

            if (value === 'end') {
                setIsEndModalOpen(true);
            } else if (value === 'begin') {
                setIsStartModalOpen(true);
            }
        }

        setActionVal((action) => {
            const previous = [...action];
            previous[index] = value;
            return previous;
        });
    };

    // Function to handle create modal open and close
    const createModalHandler = () => {
        setIsCreateModalOpen(!isCreateModalOpen);
    };

    // Function to close delete modal and reset delete target
    const deleteModalCloseHandler = () => {
        setDeleteTarget(initialDeleteTarget);
        setIsDeleteModalOpen(false);
    };

    // Close handler for End Form modal
    const endFormModalCloseHandler = () => {
        setIsEndModalOpen(false);
    };

    // Close handler for Start Form modal
    const startFormModalCloseHandler = () => {
        setIsStartModalOpen(false);
    };

    return (
        <div id="food-order-forms-div">
            <h2>Food Order Forms</h2>
            <div id="refresh-table">
                <p>Refreshing in&nbsp;
                    <Countdown onTimerComplete={refreshHandler} seconds={10} />
                    &nbsp;{isRefreshLoading 
                        ? <Oval className="refresh-oval" /> 
                        : <AiOutlineReload className="reload" onClick={refreshHandler} />
                    }
                </p>
            </div>
            <div id='data-table-wrapper'>
                <table id='food-order-table'>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Title</th>
                            <th>Created Date</th>
                            <th>Start Date</th>
                            <th>Expiration Date</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {length !== 0 && ((rowsPerPage > 0
                                ? foodOrderForms.slice(startIndex, endIndex)
                                : foodOrderForms
                            ).map((row, key, index) => {
                                return (
                                    <tr 
                                        key={`${key}-${row.key}`}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                        <td>
                                            <span className={`status-oval ${row.data.Status ? 'open' : 'closed'}`}>
                                                {row.data.Status ? 'Open' : 'Closed'}
                                            </span>
                                        </td>
                                        <td>{row.data.Title}</td>
                                        <td>
                                            {row.data.CreationDate.substring(0, row.data.CreationDate.length - 4)}
                                        </td>
                                        <td>
                                            {row.data.StartDate.substring(0, row.data.StartDate.length - 4)}
                                        </td>
                                        <td>
                                            {!!row.data.ExpirationDate 
                                                ? row.data.ExpirationDate.substring(0, row.data.ExpirationDate.length - 4) 
                                                : 'N/A'
                                            }
                                        </td>
                                        <td>
                                            <Select
                                                name="action-select"
                                                variant="outlined"
                                                className={`MuiSelect-outlined MuiSelect-outlined table-action${isHovered ? ' dark' : ' light'}`}
                                                value={actionVal[index.indexOf(row)]}
                                                onChange={(e) => actionSelectChangeHandler(e, row, index.indexOf(row))}
                                                sx={{color: 'white'}}
                                            >
                                                <MenuItem default value="action">Action</MenuItem>
                                                <MenuItem value="view">View</MenuItem>
                                                {row.data.Status 
                                                    ? <MenuItem value="end">End</MenuItem> 
                                                    : <MenuItem value="begin">Begin</MenuItem>
                                                }
                                                <MenuItem value="delete">Delete</MenuItem>
                                            </Select>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                        {(length !== 0 && emptyRows > 0) && (
                            <tr style={{ height: 52.5 * emptyRows }}>
                                <td colSpan={6} aria-hidden />
                            </tr>
                        )}
                        {length === 0 && (
                            <tr>                                
                                {isLoading 
                                    ? <td colSpan={6} className="oval-div"><Oval /></td> 
                                    : <td colSpan={6}>
                                        <Chip 
                                            className="MuiChip-root MuiChip-root info-table"
                                            icon={<FcInfo />} 
                                            label="No Forms to Display" 
                                            variant="outlined" 
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
                {length > 0 && (
                    <DataTablePagination
                        length={length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                    />
                )}
            </div>
            <div className="create-button-div">
                    <button 
                        id="create-button" 
                        className="main-button"
                        onClick={createModalHandler}
                    >
                        <CiCirclePlus />&nbsp;
                        Create New Form
                    </button>
            </div>

            {isCreateModalOpen && (
                <NewFormModal 
                    alert={alertHandler} 
                    closeHandler={createModalHandler} 
                    refresh={refreshHandler}
                    username={account.Username}
                    type="food-order"
                />
            )}

            {isEndModalOpen && (
                <FormActionModal
                    alert={alertHandler} 
                    closeHandler={endFormModalCloseHandler} 
                    refresh={refreshHandler}
                    configs={{action: 'end', type: 'food-order'}}
                    formData={formTimeData}
                />
            )}

            {isStartModalOpen && (
                <FormActionModal
                    alert={alertHandler} 
                    closeHandler={startFormModalCloseHandler} 
                    refresh={refreshHandler}
                    configs={{action: 'start', type: 'food-order'}}
                    formData={formTimeData}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteFormConfirmationModal 
                    alert={alertHandler} 
                    closeHandler={deleteModalCloseHandler} 
                    refresh={refreshHandler}
                    target={deleteTarget}
                    type="food-order"
                />
            )}
        </div>
    )
}