import React, { useState, ChangeEvent, FormEvent, useEffect, useMemo, useCallback } from 'react';
import { Box, Card, CardContent, TextField, InputAdornment, FormHelperText, CardHeader } from '@mui/material';
import { AlertBanner } from '../Alerts/AlertBanner';
import { IoReloadSharp, IoTrashBinSharp } from 'react-icons/io5';
import { BsPencilFill } from 'react-icons/bs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FaHamburger } from 'react-icons/fa';
import { GiFrenchFries } from "react-icons/gi";
import { FaGlassWaterDroplet } from 'react-icons/fa6';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { v4 as uuidv4 } from 'uuid';
import { createNewFoodOrderForm } from '../../api/firebaseApi';
import { determineDST } from '../Utils/Timer';
import { capitalizeWords } from '../Utils/stringUtils';
import '../../styles/forms.scss';

// Define screen sizes
const screens = {
    small: window.matchMedia('all and (max-device-width: 640px)').matches,
    tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
    tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
    medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
    large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
    xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
};

// Define form state types
type FormState = {
    Title: string;
    StartDate: null | Dayjs;
    ExpirationDate: null | Dayjs;
    formUUID: string;
    Options: Object;
};

type Choices = {
    Entree: string,
    Side: string,
    Drink: string,
}
  
// Login form function
export function CreateNewFoodOrderForm(props: { alert: Function; close: Function; refresh: Function; username: string; }) {
    const { alert, close, refresh, username } = props;

    // Define initial form states
    const initialFormState = {
        Title: '',
        StartDate: dayjs(),
        ExpirationDate: dayjs().add(7, 'day'),
        formUUID: uuidv4(),
        Options: {},
    };

    // Set states
    const [screenSize, setScreenSize] = useState(screens);
    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [errorDates, setErrorDates] = useState<boolean>(false);
    const [datesEqual, setDatesEqual] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(370)
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [optionNum, setOptionNum] = useState<number>(0);
    const [optionComponentList, setOptionComponentList] = useState<Object[]>([<></>]);
    const [isSelected1, setIsSelected1] = useState<boolean>(false);
    const [isSelectedList, setIsSelectedList] = useState(Array(optionNum).fill(false));
    const [isSelectedList2, setIsSelectedList2] = useState(Array(optionNum).fill(false));
    const [isSelectedList3, setIsSelectedList3] = useState(Array(optionNum).fill(false));
    const [isSelectedList4, setIsSelectedList4] = useState(Array(optionNum).fill(false));

    // useEffect for screen size changes
    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia('all and (max-device-width: 640px)').matches,
            tabletPort: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)').matches,
            tabletLand: window.matchMedia('all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)').matches,
            medium: window.matchMedia('all and (min-device-width: 1025px) and (max-device-width: 1919px)').matches,
            large: window.matchMedia('all and (min-device-width: 1920px) and (max-device-width: 2559px)').matches,
            xlarge: window.matchMedia('all and (min-device-width: 2560px)').matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    // useEffect for changing state variables based on screen size
    useEffect(() => {
        if (screenSize.small) {
            setWidth(250);
        } else if (screenSize.tabletPort) {
            setWidth(350);
        } else if (screenSize.tabletLand) {
            setWidth(400);
        } else if (screenSize.large) {
            setWidth(500);
        } else if (screenSize.xlarge) {
            setWidth(500);
        } else {
            setWidth(370);
        }
    }, [screenSize]);


    const alertHandler = (payload: object) => {
        alert(payload);
    };

    const closeModalHandler = () => {
        close();
    };

    const refreshHandler = () => {
        refresh();
    };

    const updateIsSelected = (index: number, value: boolean) => {
        setIsSelectedList((prev) => {
            const newList = [...prev];
            newList[index] = value;
            return newList;
        });
    };

    const updateIsSelected2 = (index: number, value: boolean) => {
        setIsSelectedList2((prev) => {
            const newList = [...prev];
            newList[index] = value;
            return newList;
        });
    };

    const updateIsSelected3 = (index: number, value: boolean) => {
        setIsSelectedList3((prev) => {
            const newList = [...prev];
            newList[index] = value;
            return newList;
        });
    };

    const updateIsSelected4 = (index: number, value: boolean) => {
        setIsSelectedList4((prev) => {
            const newList = [...prev];
            newList[index] = value;
            return newList;
        });
    };

    // Title Adornment
    const titleAdornment = isSelected1
    ? {
        startAdornment: (
            <InputAdornment position="start">
                <BsPencilFill style={{fontSize: '110%'}} />
            </InputAdornment>
        )
    } : {};
    
    const optionTitleAdornment = useMemo(() => {
        const options = formState.Options;
        const optionKeys = Object.keys(options);

        const deleteOption = (key: string): void => {
            // Check if the key exists in the options object
            if (!formState.Options.hasOwnProperty(key)) {
                setOptionNum((prev) => prev - 1);
                return;
            }
        
            // Create a copy of the options object and delete the specified key
            const updatedOptions = { ...formState.Options };
            delete updatedOptions[key];
        
            // Create a copy of the optionComponentList and remove the corresponding div
            const updatedList = [...optionComponentList];
            const indexToRemove = Object.keys(formState.Options).indexOf(key) + 1;
            updatedList.splice(indexToRemove, 1);
        
            setFormState((prev) => ({
                ...prev,
                Options: updatedOptions
            }));
            setOptionNum((prev) => prev - 1);
            setOptionComponentList(updatedList);
        };
        return (index: number) => {
            return isSelectedList[index]
            ? {
                startAdornment: (
                    <InputAdornment position="start">
                        <BsPencilFill style={{ fontSize: '110%' }} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <div className="trashcan" onClick={() => deleteOption(optionKeys[index])}>
                            <IoTrashBinSharp style={{ fontSize: '110%' }} />
                        </div>
                    </InputAdornment>
                ),
            } 
            : {
                endAdornment: (
                <InputAdornment position="end">
                    <div className="trashcan" onClick={() => deleteOption(optionKeys[index])}>
                        <IoTrashBinSharp style={{ fontSize: '110%' }} />
                    </div>
                </InputAdornment>
                ),
            };
        };
    }, [isSelectedList, formState, optionComponentList]);

    const optionEntreeAdornment = useMemo(() => {
        return (index: number) => {
            return isSelectedList2[index]
            ? {
                startAdornment: (
                    <InputAdornment position="start">
                        <FaHamburger style={{ fontSize: '110%' }} />
                    </InputAdornment>
                ),
            } 
            : {};
        };
    }, [isSelectedList2]);

    const optionSideAdornment = useMemo(() => {
        return (index: number) => {
            return isSelectedList3[index]
            ? {
                startAdornment: (
                    <InputAdornment position="start">
                        <GiFrenchFries style={{ fontSize: '110%' }} />
                    </InputAdornment>
                ),
            } 
            : {};
        };
    }, [isSelectedList3]);

    const optionDrinkAdornment = useMemo(() => {
        return (index: number) => {
            return isSelectedList4[index]
            ? {
                startAdornment: (
                    <InputAdornment position="start">
                        <FaGlassWaterDroplet style={{ fontSize: '110%' }} />
                    </InputAdornment>
                ),
            } 
            : {};
        };
    }, [isSelectedList4]);

    // Set new UUID
    const newUUID = () => {
        const updatedFormState = { ...formState };
        updatedFormState.formUUID = uuidv4();
        setFormState(updatedFormState);
    };

    // Check dates
    const checkDates = (start: Dayjs | null, end: Dayjs | null) => {
        if (start !== null && end !== null) {
            const startDate = new Date(start.toISOString());
            const endDate = new Date(end.toISOString());
            const comp =  startDate > endDate;
            const eq = startDate === endDate;

            if (comp) {
                setErrorDates(true);
                return false;
            } else if (eq) {
                setDatesEqual(true);
                return false;
            } else {
                setDatesEqual(false);
                setErrorDates(false);
                return true;
            }
        }
    };

    // Check the input dates for errors
    const checkStatus = (today: Dayjs | null, start: Dayjs | null) => {
        let returnVal = true;
        if (today != null && start != null) {
            const compareMonth = start.get('month') > today.get('month');
            const compareDay = start.get('date') > today.get('date');
            const compareYear = start.get('year') > today.get('year');
            const compareTime = start.format('HH:mm') > today.format('HH:mm');

            if (compareMonth || compareDay || compareYear || compareTime) {
                returnVal = false
            }
        }
        return returnVal;
    };

    function sanitizeOptionTitle(inputObject: Object) {
        // Check if the input is an object
        if (typeof inputObject !== 'object' || inputObject === null) {
          console.error('Input must be a non-null object.');
          return null;
        }
      
        // Iterate over the keys of the object
        for (const key in inputObject) {
            if (inputObject.hasOwnProperty(key)) {
                // Check if the key is 'OptionTitle'
                if (key === 'OptionTitle') {
                    // Remove the key from the object
                    delete inputObject[key];
                } else if (typeof inputObject[key] === 'object') {
                    // If the value is an object, recursively call the function
                    inputObject[key] = sanitizeOptionTitle(inputObject[key]);
                }
            }
        }
      
        return inputObject;
    };

    const checkOptions = () => {
        const options = formState.Options;
        let isValid = true;
        let messages:Array<Object> = [];
        
        // Check if options is not empty, null, or undefined
        if (!options || Object.keys(options).length === 0 || optionNum === 0) {
            messages.push({
                id: uuidv4(),
                type: 'error',
                message: 'Options cannot be blank.',
                noClose: true,
            });
            isValid = false;
        }
      
        // Check if option values are blank
        const innerObjects = Object.values(options);
        if (innerObjects.length === 0) {
            messages.push({
                id: uuidv4(),
                type: 'error',
                message: 'Option must have at least one menu item.',
                noClose: true,
            });
            isValid = false;
        }
      
        // Check if the 'Entree' field within each inner object is not null, undefined, or empty
        for (const innerObject of innerObjects) {
            if (!innerObject.Entree || innerObject.Entree.trim() === '') {
                messages.push({
                    id: uuidv4(),
                    type: 'error',
                    message: 'Entree field cannot be blank.',
                    noClose: true,
                });
                isValid = false;
            }

            // If no side or drink, add the field and set to 'none'
            if (!innerObject.Side || innerObject.Side.trim() === '') {
                innerObject.Side = 'none';
            }
            if (!innerObject.Drink || innerObject.Drink.trim() === '') {
                innerObject.Drink = 'none';
            }

        }

        if (isValid) { 
            setFormState(prevFormState => ({
                ...prevFormState,
                Options: options,
            }));
        } else {
            alertHandler(messages);
        }

        return isValid;
    };

    const capitalizeOptions = (opts:Object) => {
        const formattedOption = {};
        for (const option in opts) {
            const choices:Choices = opts[option];
            choices.Entree = capitalizeWords(choices.Entree);
            choices.Side = capitalizeWords(choices.Side);
            choices.Drink = capitalizeWords(choices.Drink);
            const formattedTitle = capitalizeWords(option);
            formattedOption[formattedTitle] = choices;
        }
        return formattedOption;
    };

    // Form submission function
    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        const checkTitle = !!formState.Title;
        const dateChecker = checkDates(formState.StartDate, formState.ExpirationDate);
        const opts = checkOptions();
        const sanitizedOptions = sanitizeOptionTitle(formState.Options);
        if (checkTitle && dateChecker && opts && sanitizedOptions) {
            setSubmitting(true);
            const status = checkStatus(dayjs(), formState.StartDate);
            const formattedOptions = capitalizeOptions(sanitizedOptions);
            const optionKeys = Object.keys(formattedOptions);
            const payload = {
                CreatedBy: username,
                CreationDate: determineDST(dayjs().format('MM/DD/YYYY h:mm A')),
                ExpirationDate: determineDST(dayjs(formState.ExpirationDate).format('MM/DD/YYYY h:mm A')),
                Options: formattedOptions,
                StartDate: determineDST(dayjs(formState.StartDate).format('MM/DD/YYYY h:mm A')),
                Status: status,
                Title: capitalizeWords(formState.Title),
            };

            const initialSubmit = {
                Choice: {
                    [optionKeys[0]]: formattedOptions[optionKeys[0]]
                },
                Comments: 'NONE',
                Email: 'admin@chucktownsound.com',
                FirstName: 'Submission',
                LastName: 'Initialization',
                Timestamp: dayjs().format('MM/DD/YYYY h:mm A'),
                Verified: true,
            }
            const response = await createNewFoodOrderForm(formState.formUUID, payload, initialSubmit);
            if (response[0]) {
                const msgPayload = {
                    id: uuidv4(),
                    type: 'success',
                    message: `Order Form: ${capitalizeWords(formState.Title)} created successfuly!`
                }
                alertHandler(msgPayload);
                setFormState(initialFormState);
                setOptionNum(0);
                refreshHandler();
            } else {
                const errorPayload = {
                    id: uuidv4(),
                    type: 'success',
                    message: response[1],
                }
                alertHandler(errorPayload);
            }
            setSubmitting(false);
        } else if (!checkTitle) {
            const payload = {
                id: uuidv4(),
                type: 'error',
                message: 'Title field cannot be blank.',
            };
            alertHandler(payload);
        }
    };

    // Form controller title
    const updateFormControlTitle = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
    ) => {
        const { value } = event.target;
        const updatedFormState = { ...formState };
        updatedFormState.Title = value;
        setFormState(updatedFormState);
    };

    // Form controller start date
    const updateFormControlStartDate = (newValue: Dayjs | null) => {
        const updatedFormState = { ...formState };
        if (newValue === null) { newValue = dayjs(); }
        updatedFormState.StartDate = newValue;
        setFormState(updatedFormState);
        checkDates(newValue, formState.ExpirationDate);
    };

    // Form controller expiration date
    const updateFormControlExpirationDate = (newValue: Dayjs | null) => {
        const updatedFormState = { ...formState };
        if (newValue === null) { newValue = dayjs().add(7, 'day'); }
        updatedFormState.ExpirationDate = newValue;
        setFormState(updatedFormState);
        checkDates(formState.StartDate, newValue);
    };

    // Form controller option title
    const updateFormControlOptionTitle = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, optionIndex: string) => {
            const { value } = event.target;
            setFormState((prevFormState) => {
                const updatedOptions = { ...prevFormState.Options };
                const currentOption = updatedOptions[optionIndex] || {};
                updatedOptions[value] = {
                    ...currentOption,
                    OptionTitle: value,
                };
                delete updatedOptions[optionIndex];
    
                return {
                    ...prevFormState,
                    Options: updatedOptions,
                };
            });
        }, [setFormState]
    );

    const updateFormControlOptionField = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, optionIndex: string, fieldName: string) => {
            const { value } = event.target;
            setFormState((prevFormState) => {
                const updatedOptions = { ...prevFormState.Options };
                const currentOption = updatedOptions[optionIndex] || {};
                updatedOptions[optionIndex] = {
                    ...currentOption,
                    [fieldName]: value,
                };
    
                return {
                    ...prevFormState,
                    Options: updatedOptions,
                };
            });
        }, [setFormState]
    );

    useEffect(() => {
        let content = <></>;
        let list = [<></>]
        const optionKeys = Object.keys(formState.Options);
        for (let i = 0; i < optionNum; i++) {
            content = (
                <div 
                    id={`option-div-${i}`} 
                    key={`option-div-${i}`} 
                    className="padded"
                >
                    <TextField
                        required
                        id={`Option-Title-Input-${i}`}
                        label="Option Title"
                        variant="outlined"
                        type="text"
                        value={optionKeys[i]}
                        onChange={e => updateFormControlOptionTitle(e, optionKeys[i])}
                        onFocus={() => updateIsSelected(i, true)}
                        onBlur={() => updateIsSelected(i, false)}
                        InputProps={optionTitleAdornment(i)}
                        sx={{width: width}}
                    />
                    <div className="padded" style={{marginBottom: '1em'}}>
                        <div className="padded indented">
                            <TextField
                                required
                                id={`Option-Entree-Input-${i}`}
                                label="Entree"
                                variant="outlined"
                                type="text"
                                value={formState.Options[optionKeys[i]]?.Entree || ''}
                                onChange={(e) => updateFormControlOptionField(e, optionKeys[i], 'Entree')}
                                onFocus={() => updateIsSelected2(i, true)}
                                onBlur={() => updateIsSelected2(i, false)}
                                onInput={() => updateIsSelected2(i, true)}
                                InputProps={optionEntreeAdornment(i)}
                            />
                        </div>
                        <div className="padded indented">
                            <TextField
                                id={`Option-Side-Input-${i}`}  
                                label="Side"
                                variant="outlined"
                                type="text"
                                value={formState.Options[optionKeys[i]]?.Side || ''}
                                onChange={(e) => updateFormControlOptionField(e, optionKeys[i], 'Side')}
                                onFocus={() => updateIsSelected3(i, true)}
                                onBlur={() => updateIsSelected3(i, false)}
                                onInput={() => updateIsSelected3(i, true)}
                                InputProps={optionSideAdornment(i)}
                            />
                        </div>
                        <div className="padded indented">
                            <TextField 
                                id={`Option-Drink-Input-${i}`}  
                                label="Drink"
                                variant="outlined"
                                type="text"
                                value={formState.Options[optionKeys[i]]?.Drink || ''}
                                onChange={(e) => updateFormControlOptionField(e, optionKeys[i], 'Drink')}
                                onFocus={() => updateIsSelected4(i, true)}
                                onBlur={() => updateIsSelected4(i, false)}
                                onInput={() => updateIsSelected4(i, true)}
                                InputProps={optionDrinkAdornment(i)}
                            />
                        </div>
                    </div>
                </div>
            );
            list.push(content);
        }
        setOptionComponentList(list);
    }, 
    [
        optionNum, 
        formState, 
        width,
        updateFormControlOptionTitle, 
        updateFormControlOptionField, 
        optionTitleAdornment,
        optionEntreeAdornment,
        optionSideAdornment,
        optionDrinkAdornment,
    ]);

    const addOption = (event: FormEvent) => {
        event.preventDefault();
        setOptionNum(optionNum + 1);
    };

    const closeAlertHandler = (banner: string) => {
        if (banner === 'dates') {
            setErrorDates(false);
        } else if (banner === 'dates-equal') {
            setDatesEqual(false);
        }
    };

    return (
        <>
            <div id="top" style={{justifyContent: 'center'}}>
                <Box sx={{ display: 'inline-block'}}>
                    <Card>
                        <form onSubmit={submitForm}>
                            <CardHeader title="Create New Food Order Form" />
                            <CardContent> 

                                {/* Title */}
                                <div className="padded">
                                    <TextField 
                                        required
                                        id="Form-Title" 
                                        label="Form Title"
                                        variant="outlined"
                                        type="text"
                                        onChange={updateFormControlTitle}
                                        value={formState.Title} 
                                        sx={{width: width}}
                                        InputProps={titleAdornment}
                                        onFocus={e => setIsSelected1(true)}
                                        onBlur={formState.Title ? undefined : e => setIsSelected1(false)}
                                        onInput={e => setIsSelected1(true)}
                                    />
                                </div>

                                {/* Date errors */}
                                {errorDates && (
                                    <div className="padded">
                                        <AlertBanner type="error" message="Start date must be BEFORE end date." close={() => closeAlertHandler('dates')} />
                                    </div>
                                )}

                                {datesEqual && (
                                    <div className="padded">
                                        <AlertBanner type="error" message="Start date and end date cannot be the same." close={() => closeAlertHandler('dates-equal')} />
                                    </div>
                                )}

                                {/* Begin Date */}
                                <div className="padded">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker 
                                            label={<span>Choose a start date/time<span className="red"> *</span></span>}
                                            value={formState.StartDate}
                                            onChange={(newValue) => updateFormControlStartDate(newValue)}
                                            sx={{width: width}} 
                                        />
                                    </LocalizationProvider>
                                </div>

                                {/* Expiration Date */}
                                <div className="padded">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker 
                                            label={<span>Choose an end date/time<span className="red"> *</span></span>} 
                                            value={formState.ExpirationDate}
                                            onChange={(newValue) => updateFormControlExpirationDate(newValue)}
                                            sx={{width: width}} 
                                        />
                                    </LocalizationProvider>
                                </div>

                                {optionComponentList.map((component) => component)}

                                <div className="padded">
                                    <button className="main-button" onClick={(event) => addOption(event)}>Add Order Option</button>
                                </div>

                                {/* Form UUID */}
                                <div className="padded">
                                    <FormHelperText>
                                        Form UUID:&nbsp;
                                        <span className="font-bg1">{formState.formUUID}</span>&nbsp;
                                        <IoReloadSharp className="uuid-reload" onClick={newUUID} />
                                    </FormHelperText> 
                                </div>
                            
                            </CardContent>
                        </form>
                    </Card>
                </Box>
            </div>
            <br />
            <button 
                className="main-button modal" 
                disabled={submitting}
                onClick={submitForm}
            >
                {submitting ? "Creating..." : "Create"}
            </button>
            <button className="outline-button modal" onClick={closeModalHandler}>Cancel</button>
        </>
    );
};
