import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDxOh5W2D5cWvyzIq9-Comn5qbbkLPtPng',
  authDomain: 'chucktownsound-v2-5fb9e.firebaseapp.com',
  projectId: 'chucktownsound-v2',
  storageBucket: 'chucktownsound-v2.appspot.com',
  messagingSenderId: '43610373354',
  appId: '1:43610373354:web:f40ca88f30050bfa802b93'
};

const appCheckConfig = {
  provider: new ReCaptchaV3Provider('6LdiL-0pAAAAAAvdjmeQRx64Nke1QMI9mZ8Z-LJh'),
  isTokenAutoRefreshEnabled: true,
};

export default function StartFire() {
  const app = initializeApp(firebaseConfig);
  initializeAppCheck(app, appCheckConfig);
  const database = getFirestore(app);
  return database;
};

export function GetAppCheck() {
  const app = initializeApp(firebaseConfig);
  return initializeAppCheck(app, appCheckConfig);
};

export function GetAuth() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  return auth;
};

export function UseAuth() {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const auth = GetAuth();
    const unSubscribe = onAuthStateChanged(auth, (user) =>
      setCurrentUser(user)
    );
    return unSubscribe;
  }, []);
  return currentUser;
};

export function GetUser() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  let loggedIn = false;
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) { loggedIn = true; } 
  });
  return loggedIn;
};

export function Storage() {
  const app = initializeApp(firebaseConfig);
  initializeAppCheck(app, appCheckConfig);
  const storage = getStorage(app);
  return storage;
};
