import React, { useState } from 'react';
import { Card } from '@mui/material';
import { CreateNewAlbumForm } from '../../components/Forms/CreateNewAlbumForm.tsx';
import { UploadImageForm } from '../../components/Forms/UploadImageForm.tsx';
import { ManageAlbums } from '../../components/Forms/ManageAlbums.tsx';
import './AdminDashboards.scss';

export function MediaDashboard(props) {
    const { alert } = props;
    const [refresher, setRefresher] = useState(false);

    const alertHandler = (payload) => {
        alert(payload);
    };

    const refreshHandler = () => {
        setRefresher(!refresher)
    };

    return (
        <>
            <div id="media-dashboard">
                <h1>Media Dashboard</h1>
                <Card id="media-card" className="MuiCard-root form-card">
                    <CreateNewAlbumForm alert={alertHandler} refreshHandler={refreshHandler} />
                    <UploadImageForm 
                        alert={alertHandler} 
                        refresh={refresher} 
                        refreshHandler={refreshHandler} 
                    />
                </Card>
                <Card id="media-management-card" className="MuiCard-root form-card">
                    <ManageAlbums
                        alert={alertHandler} 
                        refresh={refresher} 
                        refreshHandler={refreshHandler} 
                    />
                </Card>
            </div>
        </>
        
    );
};
