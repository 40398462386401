import React, { useCallback, useEffect, useState } from 'react';
// import { useParams, Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';
import { getAccountData, getEmailVerificationStatus } from '../../api/firebaseApi';
import { initialAccount } from '../../components/Utils/JsonConstants';
import { DashboardCard } from '../../components/Cards/DashboardCard';
import { DashboardHeader } from '../../components/Headers/DashboardHeader';
import { NotFoundRaw } from '../NotFound/NotFoundRaw';
import { AlertBanner } from '../../components/Alerts/AlertBanner';
import { SignAttendanceForm } from '../../components/SignForms/SignAttendanceForm';
import { PlaceFoodOrderForm } from '../../components/SignForms/PlaceFoodOrderForm';
import './Dashboard.scss';

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/dash.jpg'); 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

let checkOne = 0;

// Page for Dashboard UI
export default function Dashboard(props) {
    // Get props and initalize state variables
    const { 
        account, 
        setAccount, 
        sideBarOpen, 
        alert, 
        user, 
        close,
        setUser,
    } = props;
    const { nav } = useParams();
    const [notFound] = useState(
        nav !== undefined &&
        nav !== 'attendance' &&
        nav !== 'food-orders'
    );
    const [title, setTitle] = useState('Dashboard Home');
    const [isEmailVerified, setIsEmailVerified] = useState(true);
    // const [index, setIndex] = useState(0);
    const [openWidth, setOpenWidth] = useState('83%');
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        if (prev.tabletPort) {
            setOpenWidth('73%');
        }
        setScreenSize(prev);
    }, []);

    const headerAlertHandler = (payload) => {
        alert(payload);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setAccountHandler = useCallback((account) => setAccount(account), []);

    const determineTitle = useCallback(() => {
        if (nav !== undefined) {
            if (nav === 'attendance') {
                setTitle('Attendance');
            } else if (nav === 'food-orders') {
                setTitle('Food Orders')
            }
        } else {
            setTitle('User Dashboard');
        }
    }, [nav]);

    // useEffect to get user account data
    useEffect(() => {
        const fetch = async () => {
            const rtnData = await getAccountData(user.email);
            if (!rtnData[0]) {
                console.error(rtnData[1]);
                // Show floating error alert message
                const message = {
                    id: uuidv4(),
                    type: 'error',
                    message: `${rtnData[1]}`,
                };
                if (rtnData[1]?.code === 'permission-denied') {
                    message.message = '403: Unauthorized'
                }
                alert(message);
                setAccountHandler(initialAccount);
            } else if (rtnData[0] && rtnData[1]) {
                setAccountHandler(rtnData[1]);
            }
        }
        if (user?.email) {
            fetch();
        }
        determineTitle();
        const emailStatus = getEmailVerificationStatus();
        setIsEmailVerified(emailStatus);
    }, [
        alert,
        close,
        determineTitle,
        user,
        setAccountHandler
    ]);

    // Function to check if email has not been verified, show error message
    const checkEmailVerification = useCallback(() => {
        if (!account.Verified || !isEmailVerified) {
            const message = [
                {
                    id: uuidv4(),
                    type: 'warn',
                    message: 'Your email has not yet been verified. Please verify it to avoid account deletion.',
                },
                {
                    id: uuidv4(),
                    type: 'info',
                    message: 'Email verification settings can be found inside the \'Account Info\' section of the dashboard.',
                }
            ];
            alert(message);
        }
    }, [account, alert, isEmailVerified]);

    useEffect(() => {
        if (checkOne === 0) {
            checkEmailVerification();
            checkOne = 1;
        }
    }, [checkEmailVerification]);

    const userHandler = (userChange) => {
        setUser(userChange)
    };
    
    return (
        <section 
            id="dashboard-section" 
            style={!screenSize.small 
                ? { width: sideBarOpen ? openWidth : '97%' } 
                : {}
            }
        >
            <Helmet>
                <title>Chucktown Sound | Dashboard</title>
                <style>{bodyStyle}</style>
            </Helmet>
            
            <DashboardHeader 
                screens={screens}
                title={title}
                alert={headerAlertHandler}
                user={user}
                setUser={userHandler}
                account={account}
                sideBarOpen={sideBarOpen}
            />

            <div id="dashboard-wrapper-div" className="dashboard-wrapper-div"> 
                {nav === undefined && (       
                    <div id="dashboard-content" className="dashboard-content">
                        {!user && (
                            <AlertBanner
                                type="error"
                                message="Please log in to enable Dashboard features."
                                close={() => console.error('Unable to close.')}
                            />
                        )}
                        {!!user && (
                            <>
                                <DashboardCard 
                                    title="Your Account" 
                                    account={account} 
                                    user={user}
                                    emailStatus={isEmailVerified}
                                    type={'account'} 
                                    sideBarOpen={sideBarOpen}
                                    alert={alert}
                                />
                                <DashboardCard 
                                    title="Tools" 
                                    account={account} 
                                    type={'tools'} 
                                    sideBarOpen={sideBarOpen}
                                    alert={alert}
                                />
                                <DashboardCard 
                                    title="Information" 
                                    account={account} 
                                    type={'info'} 
                                    sideBarOpen={sideBarOpen}
                                    alert={alert}
                                />
                            </>
                        )}
                    </div>
                )}

                {nav === 'attendance' && (
                    <div id="dashboard-content" className="dashboard-content">
                        <SignAttendanceForm 
                            alert={headerAlertHandler} 
                            account={{
                                Email: account.Email,
                                FirstName: account.FirstName,
                                LastName: account.LastName,
                            }} 
                        />
                    </div>
                )}

                {nav === 'food-orders' && (
                    <div id="dashboard-content" className="dashboard-content">
                        <PlaceFoodOrderForm 
                            alert={headerAlertHandler} 
                            account={{
                                Email: account.Email,
                                FirstName: account.FirstName,
                                LastName: account.LastName,
                            }}
                        />
                    </div>
                )}

                {notFound && (
                    <div id="dashboard-content" className="dashboard-content">
                        <NotFoundRaw />
                    </div>
                )}
            </div>
        </section>
    );
};
