import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createNewAlbum, getAllAlbumNames } from '../../api/firebaseApi';
import { AlertBanner } from '../Alerts/AlertBanner';
import { generateAllSeasons } from '../Utils/stringUtils';

type Props = { 
    alert: Function; 
    refreshHandler: Function;
    refresh: Function;
};

type Message = {
    id: string;
    type: string;
    message: string;
};

type AlbumMetadata = {
    albumName: string;
    imageCount: number;
};

type AlbumData = {
    season: string;
    albums: Array<AlbumMetadata>;
}

export function CreateNewAlbumForm(props: Props) {
    const { alert, refreshHandler } = props;
    const seasonList = generateAllSeasons();
    const [name, setName] = useState<string>('');
    const [season, setSeason] = useState<string>(seasonList[0]);
    const [inputError, setInputError] = useState<boolean>(false);
    const [creating, setCreating] = useState<boolean>(false);
    const regex = /^[a-zA-Z0-9 _.]+$/;

    const handleUpload = async (e: FormEvent) => {
        e.preventDefault();
        setInputError(false);
        setCreating(true);

        const existingAlbums = await getAllAlbumNames();
        if (existingAlbums[0]) {
            let nameFound = false;
            if (existingAlbums[1] && Array.isArray(existingAlbums[1])) {
                existingAlbums[1].forEach((item: AlbumData) => {
                    if (item.season === season) {
                        item.albums.forEach((album) => {
                            if (album.albumName.toLowerCase() === name.toLowerCase()) {
                                nameFound = true;
                            }
                        });
                    }
                });
            }
            if (nameFound) {
                const message: Message = {
                    id: uuidv4(),
                    type: 'error',
                    message: `Album name: '${name}' already exists.`,
                }
                alert(message);
                setInputError(true);
                setCreating(false);
                return;
            }            
        }

        if (!regex.test(name)) {
            const message: Message = {
                id: uuidv4(),
                type: 'error',
                message: 'Album name requirements not met.',
            }
            alert(message);
            setInputError(true);
            setCreating(false);
            return;
        }

        if (name === '' || !name) {
            const message: Message = {
                id: uuidv4(),
                type: 'error',
                message: 'Album name cannot be blank.',
            }
            alert(message);
            setInputError(true);
            setCreating(false);
            return;
        }

        const res = await createNewAlbum(season, name);
        let message: Message;
        if (res[0]) {
            message = {
                id: uuidv4(),
                type: 'success',
                message: 'Album created successfully!',
            };
            setName('');
            refreshHandler();
        } else {
            message = {
                id: uuidv4(),
                type: 'error',
                message: `${res[1]}`,
            };
        }
        alert(message);
        setCreating(false);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setName(value);
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { value } = e.target;
        setSeason(value);
    };

    return (
        <div className="wrapper-div">
            <form onSubmit={handleUpload}>
                <h3>Create New Album</h3>
                <InputLabel id="season-label" required>Select Season</InputLabel>
                <Select 
                    id="season-select"
                    labelId="season-label"
                    label="Select Season"
                    onChange={handleSelectChange}
                    value={season}
                >
                    {seasonList.map((year) => <MenuItem key={uuidv4()} value={year}>{year}</MenuItem>)}
                </Select>

                <InputLabel id="create-album-label" required>Album Name</InputLabel>
                <TextField 
                    required 
                    id="album-name"
                    type="text" 
                    inputProps={{ pattern: '^[a-zA-Z0-9 _.]+$' }}
                    error={inputError} 
                    value={name}
                    onChange={handleInputChange}
                />
                <AlertBanner 
                    type="info" 
                    noHeader 
                    message="Album name must be alphanumeric, may contain spaces, and must not include any special characters other than underscores and periods."
                />
                <button id="create-album-button" className="main-button wide">{creating ? 'Creating...' : 'Create'}</button>
            </form>
        </div>
    );
};
