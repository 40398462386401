import React from 'react';
import { MdClose } from 'react-icons/md';
import { toIdFormat } from '../Utils/stringUtils';
import './Modals.scss';
import './EnlargeImageModal.scss';

export function EnlargeImageModal(props) {
    // Get props and initalize state variables
    const { closeHandler, url } = props;
    
    const handleClose = () => {
        closeHandler()
    };

    return (
        <>
            <div id="enlarge-image-modal" className="enlarge-image-modal">
                <div className="enlarge-image-modal-content">
                    <div id="close-delete" className="modal-close" onClick={handleClose}>
                        <MdClose />
                    </div>
                    <div className="enlarged-wrapper">
                        <img 
                            className="enlarged"
                            alt={`${toIdFormat(url)}-Thumbnail`} 
                            src={url} 
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
