import React, { useCallback, useEffect, useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { 
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { AccountModal } from '../Modals/AccountModal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getRehearsalData, getLeadershipContacts, sendVerificationEmail } from '../../api/firebaseApi';
import dayjs from 'dayjs';
import { AlertBanner } from '../Alerts/AlertBanner';
import { v4 as uuidv4 } from 'uuid';
import './DashboardCard.scss';

export function DashboardCard(props) {

    // Get props and initialize state variables
    const { alert, title, account, type, sideBarOpen, emailStatus } = props;
    const [adminAccess, setAdminAccess] = useState(false);
    const [instrumentModalOpen, setInstrumentModalOpen] = useState(false);
    const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
    const [rehearsals, setRehearsals] = useState();
    const [contacts, setContacts] = useState();
    const [showEmailBanner, setShowEmailBanner] = useState(true);

    useEffect(() => {
        if (account.Verified && emailStatus) {
            setShowEmailBanner(false);
        }
    }, [account, emailStatus]);

    // Define MUI theme
    const theme = createTheme({
        typography: {
            fontFamily: ['Avenir Heavy'].join(),
        },
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: {
                        color: '#db3131;',
                        '&$error': { color: '#db3131' }
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        height: 'auto',
                        minHeight: '1em',
                    },
                    label: {
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        hyphens: 'auto',
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: { textAlign: 'left' }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                        hyphens: 'auto',
                    }
                }
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: '#00000099',
                        fontWeight: 'bold',
                        margin: '0 !important',
                        marginBottom: '0.75em !important',
                    }
                }
            }
        }
    });

    const handleAlert = (message) => {
        alert(message);
    };

    const editAccountHandler = () => {
        setEditAccountModalOpen(!editAccountModalOpen);
    };

    // Define admin
    const getAdmin = useCallback(() => {
        let admin = account.Admin;
        let adminCount = 0;
        let keys = [];
        for (let key in admin) {
            if (admin.hasOwnProperty(key) && admin[key] === true) { 
                adminCount++;
                let name = key;
                if (name === 'AdminRequests') {
                    name = 'Admin Requests';
                } else if (name === 'AttendanceForms') {
                    name = 'Attendance Forms';
                } else if (name === 'FoodOrders') {
                    name = 'Food Orders';
                } else if (name === 'PhysicalInventory') {
                    name = 'Physical Inventory';
                }
                keys.push(name);
            }
        }

        if (adminCount > 0) {
            if (adminCount === 8) {
                setAdminAccess('All');
            } else {
                setAdminAccess(keys.join(', '));
            }
        } else {
            setAdminAccess(false);
        }
    }, [account.Admin]);

    useEffect(() => {
        getAdmin()
    }, [getAdmin]);

    const getRehearsals = useCallback( async () => {
        const data = await getRehearsalData();
        if (data[0]) {
            setRehearsals(data[1].Rehearsals);
        }
    }, []);

    const getContacts = useCallback( async () => {
        const data = await getLeadershipContacts();
        if (data[0]) {
            setContacts(data[1].Contacts);
        }
    }, []);

    useEffect(() => {
        getRehearsals();
        getContacts();
    }, [getRehearsals, getContacts]);

    // Display for user type
    const getUserTypeText = () => {
        if (account.Student) { 
            return "Student" 
        } else if (account.Alum && !account.Staff) { 
            return "Alum" 
        } else if (account.Alum && account.Staff) { 
            return "Staff & Alum" 
        } else { 
            return "Staff" 
        }
    };

    // Display for user academic year
    const YearText = () => {
        if (account.OtherYear) { 
            return account.OtherYear 
        } else { 
            if (!account.Year) {
                return "N/A"
            } else {
                return account.Year.replaceAll("-", " ");
            }
        }
    };

    // Display for year
    const DOBText = () => {
        let dob = "None"
        let month = account.BirthMonth;
        let day = account.BirthDay;
        if (month && day) {
            let combo = month.toString().concat("/", day.toString());
            dob = dayjs(combo.concat("/00")).format('MM/DD');
        }
        return dob;
    };

    const instrumentModalHandler = (action) => {
        setInstrumentModalOpen(action);
    };

    const sendEmail = () => {
        const res = sendVerificationEmail();
        let payload = {
            id: uuidv4(),
            type: 'success',
            message: res[1],
        };
        if (!res[0]) {
            payload.type = 'error';
        }
        handleAlert(payload);
    };

    const closeBanner = () => {
        setShowEmailBanner(false);
    };

    return (
        <div id={`list-card-${type}`} className={`dash-card ${sideBarOpen ? 'open' : ''}`}>
            <div id={`list-card-content-${type}`} className="dash-card-content" style={sideBarOpen ? {marginTop: '-5.5em'} : {}}>
                <h2>{title}</h2>
                <ThemeProvider theme={theme}>
                    {type === 'account' && (
                        <>
                            {showEmailBanner && (
                                <AlertBanner
                                    type="warn"
                                    message={
                                        <>
                                            Please verify your email. Click&nbsp;
                                                <Link 
                                                    className="alert-link" 
                                                    to="#" 
                                                    onClick={() => sendEmail()}>
                                                        HERE
                                                </Link>
                                            &nbsp;to resend the verification email.
                                        </>
                                    }
                                    close={() => closeBanner()}
                                />
                            )}
                            <Accordion>
                                <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
                                    General Info
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="account-info">
                                        <p><strong>Name: </strong><span>{account.FirstName} {account.LastName}</span></p>
                                        <p><strong>Username: </strong><span>{account.Username}</span></p>
                                        <p><strong>Email: </strong><span>{account.Email}</span></p>
                                        <p><strong>Phone: </strong><span>{account.Phone}</span></p>
                                        <p><strong>Birthday: </strong><span>{DOBText()}</span></p>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
                                    Account Type
                                </AccordionSummary>
                                <AccordionDetails>
                                <div className="account-info">
                                    <p><strong>Type: </strong><span>{getUserTypeText()}</span></p>
                                    <p><strong>Graduation: </strong><span>{account.Username}</span></p>
                                    <p><strong>Academic Year: </strong><span>{YearText()}</span></p>
                                    <p><strong>Admin Access: </strong><span>{adminAccess}</span></p>
                                </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<RiArrowDropDownLine />}>
                                    Instrument
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="account-info">
                                        {!!account.Instrument && (account.Instrument.map((instrument, index) => {
                                            return (
                                                <p key={`${index}-${instrument}`}>
                                                    <strong>{index + 1}: </strong>
                                                    <span>{instrument}</span>
                                                </p>
                                            );
                                        }))}
                                        <button className="main-button dashcard" onClick={instrumentModalHandler}>
                                            Edit Instuments
                                        </button>
                                    </div>  
                                </AccordionDetails>
                            </Accordion>
                            <button className="main-button edit-account" onClick={editAccountHandler}>Edit Account</button>
                        </>
                    )}
                    {type === 'tools' && (
                        <div>
                            {adminAccess && (
                                <Link to="/admin">
                                    <button className="main-button tools-button">Admin Dashboard</button>
                                </Link>
                            )}
                            <Link to="/dashboard/attendance">
                                <button className="main-button tools-button">Attendance</button>
                            </Link>
                            <Link to="/dashboard/food-orders">
                                <button className="main-button tools-button">Food Orders</button>
                            </Link>
                        </div>
                    )}

                    {type === 'info' && (
                        <div className="info-div">
                            <div className="info-box">
                                <h4>Rehearsal Times</h4>
                                <ul>
                                    {!rehearsals && (
                                        <li>TBD</li>
                                    )}
                                    {rehearsals?.map((rehearsal, index) => 
                                        <li key={`${index}-${rehearsal.Day}`}><strong>{rehearsal.Day} | {rehearsal.StartTime} to {rehearsal.EndTime}</strong></li>
                                    )}
                                </ul>
                                <p>
                                    Rehearsals begin promptly at the listed time. Please arrive with enough time to upack your instrument and warm up on your own.
                                </p>
                            </div>
                            <div className="info-box">
                                <h4>CTS Communication</h4>
                                <ul>
                                    <li>
                                        <strong>
                                            <a 
                                                href="https://discord.gg/5NnwQetwVP" 
                                                target="_blank" 
                                                rel="noreferrer noopener"
                                            >
                                                CTS Discord Link
                                            </a>
                                        </strong>
                                    </li>
                                </ul>
                                <p>
                                    All CTS members are <span className="required">required</span> to be in the CTS Discord server. DO NOT MUTE THE ANNOUNCEMENTS CHANNEL. Feel free to mute all other channels, however, you may miss relavent questions, comments, or concerns.
                                </p>
                            </div>
                            <div className="info-box">
                                <h4>Leadership Contact Information</h4>
                                {contacts?.map((contact, index) => {
                                    return (
                                        <div className="contact-box" key={`${index}-${contact.Email}-box`}>
                                            <h4>{contact.Role}</h4>
                                            <p><strong>Name: </strong>{contact.Name}</p>
                                            <p>
                                                <strong>Email: </strong>
                                                <a 
                                                    href={`mailto:${contact.Email}`} 
                                                    target="_blank" 
                                                    rel="noreferrer noopener"
                                                >
                                                    {contact.Email}
                                                </a>
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {instrumentModalOpen && (
                        <AccountModal 
                            alert={handleAlert} 
                            closeHandler={instrumentModalHandler} 
                            account={account}
                            type={'instrument-edit'}
                        />
                    )}

                    {editAccountModalOpen && (
                        <AccountModal 
                            alert={handleAlert} 
                            closeHandler={editAccountHandler} 
                            account={account}
                            type={'account-edit'}
                        />
                    )}
                </ThemeProvider>
            </div>
        </div>
    );
};
