import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getImagesByAlbum } from '../../api/firebaseApi';
import { toIdFormat, capitalizeWords } from '../Utils/stringUtils';
import placeholder from '../../assets/photo-placeholder.png';
import './DashboardCard.scss';
import { Skeleton } from '@mui/material';

export const AlbumCard = (props) => {
    // Get props and initialize state variables
    const { 
        title, 
        season, 
        sideBarOpen, 
        alert, 
        thumbnailWidth, 
        thumbnailHeight,
    } = props;
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const uuid = uuidv4();

    const alertHandler = useCallback((payload) => {
        alert(payload);
    }, [alert]);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const res = await getImagesByAlbum(season, title);
            if (res[0]) {
                const urls = res[1];
                setUrl(urls[0]);
            } else {
                const payload = {
                    id: uuidv4(),
                    type: 'error',
                    message: res[1],
                }
                alertHandler(payload);
            }
            setLoading(false);
        };
        if (title) {
            fetch();
        }
    }, [title, alertHandler, season]);

    return (
        <Link to={`/media/photos/${season}?album=${toIdFormat(title)}`}>
            <div id={`album-card-${uuid}`} className={`album-card ${sideBarOpen ? 'open' : ''}`}>
                <div 
                    id={`album-card-content-${uuid}`} 
                    className="dash-card-content album"
                    style={sideBarOpen ? {marginTop: '-5.5em'} : {}}
                >
                    <h2>{capitalizeWords(title)}</h2>
                    {loading && (
                        <Skeleton 
                            sx={{ margin: '0 auto', borderRadius: '10px'}} 
                            animation="wave" 
                            variant="rectangular"
                            width={thumbnailWidth} 
                            height={thumbnailHeight} 
                        />
                    )}
                    {!loading && (
                        <img 
                            className={`thumbnail${url ? '' : ' holder'}`} 
                            alt={`${toIdFormat(title)}-Thumbnail`} 
                            src={url ? url : placeholder} 
                        />
                    )}
                </div>
            </div>
        </Link>
    );
};
