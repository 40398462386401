import React from 'react';
import { MdClose } from 'react-icons/md';
import { UpdateAlbumNameForm } from '../Forms/UpdateAlbumNameForm.tsx';
import './Modals.scss';
import './UpdateAlbumNameModal.scss';


export function UpdateAlbumNameModal(props) {
    // Get props and initalize state variables
    const { alert, closeHandler, refresh, season, oldAlbumName } = props;
    
    const handleClose = (action) => {
        console.log('action:', action)
        closeHandler(action)
    };

    const alertHandler = (payload) => {
        alert(payload);
    };

    const refreshHandler = () => {
        refresh();
    };

    return (
        <>
            <div id="update-album-name-form-modal" className="update-album-name-form-modal">
                <div className="update-album-name-form-modal-content">
                    <div id="close-new" className="modal-close" onClick={() => handleClose()}>
                        <MdClose />
                    </div>
                    <h2>Edit Album Name</h2>
                    <UpdateAlbumNameForm
                        alert={alertHandler}
                        season={season}
                        oldAlbumName={oldAlbumName}
                        refreshHandler={refreshHandler}
                        closeHandler={handleClose}
                    />
                </div>
            </div>
        </>
    );
};
