import { Chip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { deleteFilesInFolder, getAllAlbumNames } from '../../api/firebaseApi';
import { IoTrashBinSharp } from 'react-icons/io5';
import { Oval } from 'react-loading-icons';
import { FcInfo } from 'react-icons/fc';
import { AlertBanner } from '../Alerts/AlertBanner';
import { UpdateAlbumNameModal } from '../Modals/UpdateAlbumNameModal';

type Props = { 
    alert: Function; 
    refreshHandler: Function;
    refresh: Function;
};

type Message = {
    id: string;
    type: string;
    message: string;
};

type AlbumMetadata = {
    albumName: string;
    imageCount: number;
};

type AlbumData = {
    season: string;
    albums: Array<AlbumMetadata>;
};

type Album = {
    season: string;
    album: string;
};

type ErrorStack = {
    message: string;
    stack: string;
};

type ModalData = {
    season: string;
    album: string;
}

export function ManageAlbums(props: Props) {
    const { alert, refresh, refreshHandler } = props;
    const [data, setData] = useState<Array<AlbumData>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
    const [modalSeason, setModalSeason] = useState<string>('');
    const [oldAlbumName, setOldAlbumName] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const alertHandler = (payload: Message) => {
        alert(payload);
    };

    const handleRefresh = () => {
        refreshHandler();
    };

    const fetchData = useCallback( async () => {
        setIsLoading(true);
        const res = await getAllAlbumNames();
        if (res[0] && res[1] && typeof res[1] === 'object') {
            setData(res[1]);
            setIsError(false);
        } else if (!res[0]) {
            const message = {
                id: uuidv4(),
                type: 'error',
                message: res[1],
                noClose: true,
            };
            alert(message);
            setIsError(true);
        }
        setIsLoading(false);
    }, [alert])

    useEffect(() => {
        if ((data.length === 0 || refresh) && !isError) {
            fetchData();
        }
    }, [data.length, refresh, fetchData, isError]);

    const handleDelete = async (data: Album) => {
        if (!isDeleting) {
            setIsDeleting(true);
            const { season, album } = data;
            const res = await deleteFilesInFolder(season, album);
            let payload: Message;
            if (res[0]) {
                payload = {
                    id: uuidv4(),
                    type: 'success',
                    message: `'${album}' deleted successfully`
                }
                handleRefresh();
                alert(payload);
            } else if (!res[0] && res[1]) {
                let message: string;
                if (typeof res[1] === 'object') {
                    const error:ErrorStack = res[1];
                    message = error.message;
                } else {
                    message = res[1].toString();
                }
                payload = {
                    id: uuidv4(),
                    type: 'error',
                    message,
                }
                alert(payload);
            }
            setIsDeleting(false);
        }
    };

    const modalToggleHandler = (toggle: boolean, data: ModalData | undefined) => {
        setOpenRenameModal(toggle);
        if (data) {
            setModalSeason(data.season)
            setOldAlbumName(data.album);
        } else {
            setModalSeason('')
            setOldAlbumName('');
        }
    };

    return (
        <>
            <div className="wrapper-div">
                <h3 id="manage-media-label">Manage Albums</h3>
                {isDeleting && (
                    <AlertBanner type="info" message="Deletion in progress. This may take some time. Do not refresh the page." noHeader />
                )}
                <table>
                    <thead>
                        <tr>
                            <th>Album Name</th>
                            <th>Season</th>
                            <th>Total Images</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(data && data.length > 0) && (
                            data.map((albumData) => {
                                return albumData.albums.map((album) => {
                                    return (
                                        <tr key={`${albumData.season}-${album.albumName}`}>
                                            <td>{album.albumName}</td>
                                            <td>{albumData.season}</td>
                                            <td>{album.imageCount}</td>
                                            <td>
                                                <button 
                                                    onClick={() => 
                                                        modalToggleHandler(true, {album: album.albumName, season: albumData.season})
                                                    }
                                                    className="main-button"
                                                >
                                                    Rename
                                                </button>
                                            </td>
                                            <td>
                                                <div 
                                                    className={`trashcan fit white-svg${isDeleting ? ' disabled' : ''}`}
                                                    onClick={() => 
                                                        handleDelete({album: album.albumName, season: albumData.season})
                                                    }
                                                >
                                                    {isDeleting
                                                        ? <Oval stroke="#000" />
                                                        : <IoTrashBinSharp style={{ fontSize: '110%' }} />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            })
                        )}
                        {(!data || (data && data.length === 0)) && (
                            <tr>                                
                                {isLoading 
                                    ? <td colSpan={5} className="oval-div"><Oval /></td> 
                                    : <td colSpan={5}>
                                        <Chip 
                                            className="MuiChip-root MuiChip-root info-table"
                                            icon={<FcInfo />} 
                                            label="No Albums to Display" 
                                            variant="outlined" 
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>

                <h3 id="manage-media-label">Manage Seasons</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Season Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(data && data.length > 0) && (
                            data.map((albumData) => {
                                return (
                                    <tr key={albumData.season}>
                                        <td>{albumData.season}</td>
                                        <td>
                                            <div 
                                                className={`trashcan fit white-svg${isDeleting ? ' disabled' : ''}`}
                                                onClick={() => handleDelete({album: '', season: albumData.season})}
                                            >
                                                {isDeleting
                                                    ? <Oval stroke="#000" />
                                                    : <IoTrashBinSharp style={{ fontSize: '110%' }} />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                        {(!data || (data && data.length === 0)) && (
                            <tr>                                
                                {isLoading 
                                    ? <td colSpan={4} className="oval-div"><Oval /></td> 
                                    : <td colSpan={4}>
                                        <Chip 
                                            className="MuiChip-root MuiChip-root info-table"
                                            icon={<FcInfo />} 
                                            label="No Seasons to Display" 
                                            variant="outlined" 
                                        />
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {openRenameModal && (
                <UpdateAlbumNameModal
                    alert={alertHandler}
                    closeHandler={modalToggleHandler}
                    refresh={handleRefresh}
                    season={modalSeason}
                    oldAlbumName={oldAlbumName}
                />
            )}
        </>
    );
};