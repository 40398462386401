import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import image from '../../assets/404.png';
import './NotFound.scss';

// Define background change
const bodyStyle = `body { background-image: url('/public-images/not-found.jpeg'); transition: all 1s ease-in-out; }`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

export function NotFound(props) {
    const { sideBarOpen } = props;
    const [screenSize, setScreenSize] = useState(screens);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    return (
        <section id="not-found-section" style={!screenSize.small ? {width: sideBarOpen ? '85%' : '97%'} : {}}>
            <Helmet>
                <title>Chucktown Sound | Page Not Found</title>
                <style>{bodyStyle}</style>
            </Helmet>
            <div id="not-found-wrapper-div" className="not-found-wrapper-div">
                <div id="not-found-content" className="not-found-content">
                    <div>
                        <img src={image} alt='not-found.png' />
                    </div>
                    <h1>404: Page Not Found</h1>
                    <p>Sorry, we couldn't find the page you were looking for.</p>
                    <p id="check-back">Please check the URL and try again.</p>
                </div>
            </div>
        </section>
    );
};
