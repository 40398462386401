import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TfiArrowCircleLeft } from 'react-icons/tfi';
import { AiFillHome, AiOutlineMessage } from 'react-icons/ai';
import { BiPhotoAlbum, BiDonateHeart } from 'react-icons/bi';
import { BsCameraVideo, BsCalendar3, BsInfoCircle } from 'react-icons/bs';
import { FaBars } from 'react-icons/fa6';
import { IconContext } from 'react-icons/lib';
import { 
    MdAccountCircle,
    MdClose, 
    MdOutlineSpaceDashboard, 
    MdLibraryMusic, 
    MdOutlinePermMedia,
} from 'react-icons/md';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import MobileSubMenu from './MobileSubMenu'
import logoCursive from '../../assets/ctwnsnd2.png';
import pfp from '../../assets/image-placeholder.jpeg';
import { v4 as uuidv4 } from 'uuid';
import { sendLogOut } from '../../api/firebaseApi';
import './Sidebar.scss';

// Define screen sizes
const screens = {
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

// Component for sidebar navigation
export function Sidebar(props) {
    // Get props and initialize state variable
    const { account, alert, isOpen, changeOpen, user, setUser } = props;
    const [expanded, setExpanded] = useState(false);
    const [screenSize, setScreenSize] = useState(screens);
    const [width, setWidth] = useState('3%');
    const [widthOpen, setWidthOpen] = useState('15%');
    const name = `${account.FirstName} ${account.LastName}`

    useEffect(() => {
        const screenSizes = {
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    useEffect(() => {
        if (screenSize.tabletPort) {
            setWidth('5%');
            setWidthOpen('25%');
        } else if (screenSize.tabletLand) {
            setWidth('5%');
            setWidthOpen('18%');
        } else {
            setWidth('3%');
            setWidthOpen('15%');
        }
    }, [screenSize]);

    // Function to handle opening and closing the sidebar
    const openerHandler = () => {
        changeOpen();
    };

    const expandHandler = () => {
        setExpanded(!expanded);
    };

    // Function to handle log out
    const logOutHandler = async () => {
        const loggedOut = await sendLogOut();
        if (loggedOut[0]) {
            const alertPayload = {
                id: uuidv4(),
                type: 'success',
                message: 'You have been successfully logged out.',
            };
            setUser(undefined);
            alert(alertPayload);
        } else {
            const alertPayload = {
                id: uuidv4(),
                type: 'error',
                message: `${loggedOut[1]}`,
            };
            alert(alertPayload);
        }
    };

    return (
        <section id="sidebar" style={{width: isOpen ? widthOpen : width}} className={isOpen ? '' : 'glass'}>
            <div id="nav-control-div" className={`nav-control-div ${isOpen ? 'closed' : ''}`}>
                <IconContext.Provider value={{className: `arrow ${isOpen ? 'rotate' : ''}`}}>
                    {isOpen ? <img src={logoCursive} alt="logo.png" /> : <></>}
                    <h1 className="logo-font">
                        <TfiArrowCircleLeft style={{fontSize: 25}} onClick={openerHandler} />
                    </h1>
                </IconContext.Provider>
            </div>
            <hr style={{margin: isOpen ? '1.5em 1em 20px 1em' : '1.5em 7px 5px 7px'}} />
            {isOpen && (
                <div id="nav-list">
                    <Link to={`/${'dashboard'}`}>
                        <div id="account-card" className="account-card">
                            <img src={pfp} alt="task-master-logo.png" />
                            {!!user
                                ? <span>
                                    <h5>{name}</h5>
                                    {account.Student && (
                                        <p>
                                            Student • <span className="logout" onClick={logOutHandler}>Log Out</span>
                                        </p>
                                    )}
                                    {account.Staff && (
                                        <p className="mini">
                                            Staff • <Link to="#" className="logout" onClick={logOutHandler}>Log Out</Link>
                                        </p>
                                    )}
                                </span>
                                : <span>
                                    <Link to="/login">Log In</Link> / <Link to="/create-account">Create</Link>
                                </span>
                            }
                        </div>
                    </Link>
                    <Link to="/home">
                        <div id="home-nav" className="nav-button">
                            <AiFillHome id="home-nav-icon" />
                            <span>Home</span>
                        </div>
                    </Link>
                    <Link to="/dashboard">
                        <div id="dashboard-nav" className="nav-button">
                            <MdOutlineSpaceDashboard id="dashboard-nav-icon" />
                            <span>Dashboard</span>
                        </div>
                    </Link>
                    <Link to="/about">
                        <div id="about-nav" className="nav-button">
                            <BsInfoCircle id="about-nav-icon" />
                            <span>About</span>
                        </div>
                    </Link>
                    <Link to="/donate">
                        <div id="about-nav" className="nav-button">
                            <BiDonateHeart id="about-nav-icon" />
                            <span>Donate</span>
                        </div>
                    </Link>
                    <Link to="/schedule">
                        <div id="schedule-nav" className="nav-button">
                            <BsCalendar3 id="schedule-nav-icon" />
                            <span>Schedule</span>
                        </div>
                    </Link>
                    <Link to="#" onClick={expandHandler}>
                        <div id="about-nav" className="nav-button">
                            <MdOutlinePermMedia id="media-nav-icon" />
                            <span>Media</span>
                            <RiArrowDownSFill 
                                id="media-nav-expand-icon" 
                                className={`expand-icon ${expanded ? 'flip' : ''}`} 
                            />
                        </div>
                    </Link>
                    {expanded && (
                        <Link to="/media/photos">
                            <div id="media-photos-nav" className="nav-button sub-nav">
                                <BiPhotoAlbum id="photos-nav-icon" />
                                <span>Photos</span>
                            </div>
                        </Link>
                    )}
                    {expanded && (
                        <Link to="/media/videos">
                            <div id="media-videos-nav" className="nav-button sub-nav">
                                <BsCameraVideo id="videos-nav-icon" />
                                <span>Videos</span>
                            </div>
                        </Link>
                    )}
                    <Link to="/auditions">
                        <div id="auditions-nav" className="nav-button">
                            <MdLibraryMusic id="auditions-nav-icon" />
                            <span>Auditions</span>
                        </div>
                    </Link>
                    <Link to="/contact">
                        <div id="contact-nav" className="nav-button">
                            <AiOutlineMessage id="contact-nav-icon" />
                            <span>Contact Us</span>
                        </div>
                    </Link>
                    <Link to="report-a-problem-form">
                        <div id="report-a-problem-link" className="nav-link">
                            <p>Report a Problem</p>
                        </div>
                    </Link>
                </div>
            )}

            {!isOpen && (
                <div id="nav-list" className="nav">
                    <IconContext.Provider value={{className: 'nav-icons'}}>
                        <Link to={`/${'dashboard'}`}>
                            <MdAccountCircle />
                        </Link>
                        <hr style={{margin: '2.5px 7px'}} />
                        <Link to="/home">
                            <AiFillHome id="home-nav-icon" />
                        </Link>
                        <Link to="/dashboard">
                            <MdOutlineSpaceDashboard id="dashboard-nav-icon" />
                        </Link>
                        <Link to="/about">
                            <BsInfoCircle id="about-nav-icon" />
                        </Link>
                        <Link to="/donate">
                            <BiDonateHeart id="about-nav-icon" />
                        </Link>
                        <Link to="/schedule">
                            <BsCalendar3 id="schedule-nav-icon" />
                        </Link>
                        <hr style={{margin: '2.5px 7px'}} />
                        <Link to="#" onClick={expandHandler}>
                            <MdOutlinePermMedia id="media-nav-icon" />
                        </Link>
                        {expanded && (
                            <Link to="/media/photos">
                                <BiPhotoAlbum id="photos-nav-icon" className="fade" />
                            </Link>
                        )}
                        {expanded && (
                            <Link to="/media/videos">
                                <BsCameraVideo id="videos-nav-icon" className="fade" />
                            </Link>
                        )}
                        <hr style={{margin: '2.5px 7px'}} />
                        <Link to="auditions">
                            <MdLibraryMusic id="auditions-nav-icon" />
                        </Link>
                        <Link to="/contact">
                            <AiOutlineMessage id="contact-nav-icon" />
                        </Link>
                    </IconContext.Provider>
                </div>
            )}
        </section>
    );
};

// Define the navigation options for the mobile sidebar
const SidebarMobileData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiFillHome />
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <MdOutlineSpaceDashboard />
    },
    {
        title: 'About',
        path: '/about',
        icon: <BsInfoCircle />
    },
    {
        title: 'Donate',
        path: '/donate',
        icon: <BiDonateHeart />
    },
    {
        title: 'Schedule',
        path: '/schedule',
        icon: <BsCalendar3 />
    },
    {
        title: 'Media',
        path: '#',
        icon: <MdOutlinePermMedia />,
        iconClosed: <RiArrowDownSFill />,
        iconOpened: <RiArrowUpSFill />,
        subNav: [
            {
            title: 'Photos',
            path: '/media/photos',
            icon: <BiPhotoAlbum />
            },
            {
            title: 'Videos',
            path: '/media/videos',
            icon: <BsCameraVideo />
            }
        ]
    },
    {
        title: 'Auditions',
        path: '/auditions',
        icon: <MdLibraryMusic />
    },
    {
        title: 'Contact Us',
        path: '/contact',
        icon: <AiOutlineMessage />
    }
];

// Component for the mobile sidebar
export const SidebarMobile = () => {
    // Initialize state variables
    const [isOpen, setIsOpen] = useState(false);
    const [visable, setVisible] = useState(true);
    const location = useLocation();

    // Function to toggle sidebar button visibility based on how far the user scrolls
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 25){
            setVisible(false)
        } 
        else if (scrolled <= 25){
            setVisible(true)
        }
    };

    // Event listener for scrolling
    window.addEventListener('scroll', toggleVisible);

    // Function to handle opening and closing the sidebar
    const openerHandler = () => {
        setIsOpen(!isOpen);
    };

    // Mouse leave bars negation
    const mouseLeaver = () => {
        setIsOpen(false);
    };

    // Mouse leave bars negation
    const mouseEnter = () => {
        setIsOpen(true);
    };

    return (
        <>
            <div id="nav-bars" className="nav-bars-div" onClick={openerHandler} onMouseLeave={mouseLeaver}>
                <Link to={`${location.search}#`}>
                    <IconContext.Provider value={{className: visable ? 'bars' : 'bars invisible'}}>
                        <FaBars />
                    </IconContext.Provider>
                </Link>
            </div>
            <nav className={isOpen ? 'open-nav' : ''}>
                <div className="sidebar-wrap-div" onMouseEnter={mouseEnter} onMouseLeave={mouseLeaver}>
                    <div className="close-div">
                        <Link to={`${location.search}#`} onClick={openerHandler}>
                            <MdClose />
                        </Link>
                    </div>
                        
                    {SidebarMobileData.map((item, index) => {
                        return <MobileSubMenu item={item} key={index} />;
                    })}
                </div>
            </nav>
        </>
    );
};
